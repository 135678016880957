<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header">
                    <h5>Menu Categories</h5>
                </div>
                <div class="card-body">
                    <div class="btn-popup pull-right">
                        <button type="button" class="btn btn-primary" data-toggle="modal" data-original-title="test"
                            data-target="#exampleModal" (click)="open()">Add Menu Category</button>      
                        <button type="button" class="btn btn-primary" data-toggle="modal" data-original-title="test"
                            data-target="#exampleModal" (click)="openIndexOrder()" style="margin-left: 10px;">Select Category Order</button>                 
                    </div>
                    <app-menu-categories-list></app-menu-categories-list>
                </div>
            </div>
        </div>
    </div>
</div>
<app-menu-categories-modal #menuCategoriesModal [addOrEdit]="'Add'" [categoryId]="selectedId"></app-menu-categories-modal>
<app-menu-category-index-modal #menuCategoriesIndexModal [restaurantId]="selectedRestaurantId"></app-menu-category-index-modal>