<ng-template #menuCategoryIndexModal let-modal>
    <div class="modal-header">
        <h5 class="modal-title f-w-600" id="exampleModalLabel">Menu Categories</h5>
        <button type="button" class="close" aria-label="Close"
            (click)="modal.dismiss('Cross click');clearState()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
            <div class="form">  
                <ng-container *ngIf="_menuCategories | async as extraOptionsRes">
                <div cdkDropList class="example-list" (cdkDropListDropped)="drop($event)">
                    <div class="example-box" *ngFor="let extraOption of extraOptionsRes " cdkDrag>
                        {{extraOption.categoryName}}
                    </div>
                </div>
            </ng-container>
            </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="modal.dismiss('Cross click');" (mouseup)="saveIndexes()">Save</button>
        <button class="btn btn-secondary" type="button" data-dismiss="modal"
            (click)="modal.dismiss('Cross click');clearState()">Close</button>
    </div>
</ng-template>