import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  OnInit,
  QueryList,
  SimpleChange,
  ViewChild,
  ViewChildren,
} from "@angular/core";
import { MenuItemExtraWithOptionsRequestModel } from "../../tables/menu-item-extra-request";
import { MenuItemExtraOptionRequest } from "../../tables/menu-item-extra-option-request";
import {
  ModalDismissReasons,
  NgbModal,
  NgbModalRef,
} from "@ng-bootstrap/ng-bootstrap";
import { MenuItemsService } from "../../service/menu.items.service";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { BehaviorSubject, Observable, Subject, map, observable, startWith } from "rxjs";
import { v4 as uuid } from "uuid";
import { MenuItemExtra } from "../../tables/menu-item-extra";
import { MenuItemRequestModel } from "../../tables/menu-item-request";
import { NgbdSortableHeader } from "../../directives/NgbdSortableHeader";
import { DomSanitizer } from "@angular/platform-browser";
import { CategoryService } from "../../service/category.service";
import { FormControl } from "@angular/forms";
import sanityClient from '@sanity/client'
import { environment } from 'src/environments/environment';
import { RestaurantRequestModel } from "../../tables/restaurantRequestModel";
import { RestaurantService } from "../../service/restaurant.service";
import { ServiceProviderGroupRequest } from "../../tables/serviceProviderRequestModel";

const client = sanityClient({
  projectId: 'wl9gemnw', // find this at manage.sanity.io or in your sanity.json
  dataset: 'production', // or the name you chose in step 1
  useCdn: true, // `false` if you want to ensure fresh data
  token: environment.NEXT_PUBLIC_SANITY_TOKEN
})

@Component({
  selector: "app-restaurant-modal",
  templateUrl: "./restaurant-modal.component.html",
  styleUrls: ["./restaurant-modal.component.scss"],
})
export class RestaurantModalComponent implements AfterViewInit, OnInit {
  myControl = new FormControl<string | ServiceProviderGroupRequest>('');
  options: ServiceProviderGroupRequest[] = [];
  filteredOptions: Observable<ServiceProviderGroupRequest[]>;

  menuItemExtras$: Observable<MenuItemExtraWithOptionsRequestModel[]>;
  menuItemImage: any;

  @ViewChild("restaurantModal") menuItemModal: NgbModalRef;
  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  @Input() addOrEdit: string;
  @Input() restaurantId: Observable<string> = new Observable<string>();

  public closeResult: string;
  searchText;
  total$: Observable<number>;

  restaurant$: Observable<RestaurantRequestModel> =
    new Observable<RestaurantRequestModel>();

  menuItemExtra: any = {};

  public _menuItemExtras = new BehaviorSubject<any[]>([]);
  menuItemExtraWithOptions: MenuItemExtraWithOptionsRequestModel = {
    name: "",
    description: "",
    isRequired: false,
    menuItemExtraId: "",
    assignedExtraIndex: 0,
    menuItemExtraOptions: [],
    optionsLimit: 1,
    restuarantId: "",
  };
  menuItemExtraWithOptionsToAdd: MenuItemExtraWithOptionsRequestModel[] = [];

  selected = 'option2';
  public menuCategories$: Observable<any[]>;
  public menuCategories: Array<any>=[];
  public _menuCategories = new BehaviorSubject<any[]>([]);

  constructor(
    private modalService: NgbModal,
    public restaurantService: RestaurantService,
    public categoryService: CategoryService,
    private sanitizer: DomSanitizer
  ) {

  }
  ngOnInit(): void {
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => {
        const name = typeof value === 'string' ? value : value?.name;
        return name ? this._filter(name as string) : this.options.slice();
      }),
    );
  }

  open() {
    this.modalService
      .open(this.menuItemModal, { ariaLabelledBy: "modal-basic-title" })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }
  openMenuExtraOption(content) {
    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title" })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  createOrEditRestaurant() {
    this.restaurant$.subscribe((v) => {
      if(this.myControl.value !== '')
      {
        const selectedvalue = this.myControl.value as ServiceProviderGroupRequest;
        v.serviceProviderGroupId = selectedvalue.id;
      }
      if (v.id === undefined) {
        v.id = uuid();
        this.restaurantService
          .addRestaurant(
            v
          )
          .subscribe((res) => {});
      } else {
        this.restaurantService
          .editRestaurant(
            v
          )
          .subscribe((res) => {});
      }

      console.log(v);
    });
  }

  addAndIndexExtras(menuItemWithExtraWithOptions: MenuItemRequestModel) {
    //index options based on array position
    this._menuItemExtras.value.forEach((o) => {
      o.assignedExtraIndex = this._menuItemExtras.value.indexOf(o);
    });
    menuItemWithExtraWithOptions.menuItemExtraWithOptions =
      this._menuItemExtras.value;

    return menuItemWithExtraWithOptions;
  }

  changeRestaurantProps(event: any) {
    this.restaurant$ = this.restaurantService.UpdateMenuItem(event);
  }

  onChange(event: any) {
    console.log("Toggle changed to: ", event.checked);
  }

  async readUrl(event: any, menuItem: any) {
    if (event.target.files.length === 0) return;
    //Image upload validation
    var mimeType = event.target.files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    // Image upload
    console.log(event.target.files[0]);
    console.log(environment.NEXT_PUBLIC_SANITY_TOKEN)
    try {
      // Step 1: Upload the image asset
      const imageAsset = await client.assets.upload('image', event.target.files[0], {
        contentType: event.target.files[0].type,
        filename: uuid()
      });
      var imageTitle = uuid().toString();
      // Step 2: Create a document using the image asset's _id
      const doc = {
        _type: 'qFlowImage',
        title: imageTitle,
        qFlowImage: {
          _type: 'image',
          asset: {
            _type: 'reference',
            _ref: imageAsset._id
          }
        }
      };
  
      const createdDoc = client.create(doc).then(docCreated =>{
        client.fetch(`*[_type == 'qFlowImage'&& title == '`+imageTitle+`'][0]{
          title,
          "imageUrl": qFlowImage.asset->url
        }`).then(image => {
          console.log(image.imageUrl);
          menuItem.featuredImage = image.imageUrl;
          this.restaurant$ = this.restaurantService.UpdateMenuItem(menuItem);                      
        })
      }
      );
  
      console.log(createdDoc);
    } catch (error) {
      console.error('Upload failed:', error.message);
    }
  }

  public getImage(fileArray: string) {
    if (!(fileArray as string).includes("data:")) {
      let objectURL = (fileArray) as string;
      let image = this.sanitizer.bypassSecurityTrustUrl(objectURL);
      return image;
    }
    let image = this.sanitizer.bypassSecurityTrustUrl(fileArray as string);
    return image;
  }

  ngAfterViewInit() {
    this.restaurantId.subscribe((m) => {
      if (m.length > 0) {
        this.restaurant$ = this.restaurantService.getRestaurantById(m);
        this.restaurantService.getAllServiceProviderGroups().subscribe(sp =>{
          this.options = sp;
          this.restaurant$.subscribe(r =>{
            if(r.serviceProviderGroupId !== undefined)
            {
              const selectedGroup = this.options.filter(o => {return o.id === r.serviceProviderGroupId})[0]          
              this.myControl.setValue(selectedGroup)
            }
          })
        });

      } else {
        this.restaurant$ = this.restaurantService.restaurantToEditInitialize;
      }
    });
  }

  clearState() {
    this._menuItemExtras.unsubscribe();
    this._menuItemExtras = new BehaviorSubject<any[]>([]);
  }

  public displayFn(user: ServiceProviderGroupRequest): string {
    return user && user.name ? user.name : '';
  }

  private _filter(name: string): ServiceProviderGroupRequest[] {
    const filterValue = name.toLowerCase();

    return this.options.filter(option => option.name.toLowerCase().includes(filterValue));
  }
}
