import { DecimalPipe } from '@angular/common';
import { Component, EventEmitter, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { AdminUserCreateModalComponent } from 'src/app/shared/components/admin-user-create-modal/admin-user-create-modal.component';
import { MenuItemModalComponent } from 'src/app/shared/components/menu-item-modal/menu-item-modal.component';
import { RestaurantModalComponent } from 'src/app/shared/components/restaurant-modal/restaurant-modal.component';
import { NgbdSortableHeader } from 'src/app/shared/directives/NgbdSortableHeader';
import { CategoryService } from 'src/app/shared/service/category.service';
import { MenuItemsService } from 'src/app/shared/service/menu.items.service';
import { RestaurantService } from 'src/app/shared/service/restaurant.service';
import { CATEGORY } from 'src/app/shared/tables/category';

@Component({
  selector: 'app-region-management-list',
  templateUrl: './region-management-list.component.html',
  styleUrls: ['./region-management-list.component.scss']
})
export class RegionManagementListComponent {
  searchText;
  tableItem$: Observable<any>;
  total$: Observable<number>;
  restuarantId:string = '';

  public selectedId : EventEmitter<string> = new EventEmitter<string>();
  public selectedRestaurantName : EventEmitter<string> = new EventEmitter<string>();
  @ViewChild('restaurantModal') private modalComponent!: RestaurantModalComponent;
  @ViewChild('adminUserCreateModal') private adminCreateModalComponent!: AdminUserCreateModalComponent;

  selectedMenuItemId:string;

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  constructor(public menuItemService: MenuItemsService,private sanitizer: DomSanitizer, public categoryService: CategoryService, private restaurantService: RestaurantService) {
      this.tableItem$ = restaurantService.getAllServiceProviderGroups();
      // this.tableItem$.subscribe(res => console.log(res) )
      // this.total$ = menuItemService.total$;
    this.menuItemService.setUserData(CATEGORY)
  }
  
  onSort({ column, direction }) {
    // resetting other headers
    console.log("dsafsa");

    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.menuItemService.sortColumn = column;
    this.menuItemService.sortDirection = direction;

  }

  editRestaurant(selectedId : string){
    this.selectedMenuItemId = selectedId;
    this.selectedId.emit(selectedId);
    this.modalComponent.open();
  }

  deleteRestaurant(restaurantId : string){
    this.restaurantService.deleteServiceProviderGroup(restaurantId).subscribe();
  }

  manageAdminUser(selectedId : string, restaurantName: string){
    this.selectedMenuItemId = selectedId;
    this.selectedId.emit(selectedId);
    this.selectedRestaurantName.emit(restaurantName);
    this.adminCreateModalComponent.open();
  }

  public getImage(fileArray: string) {
    if (!(fileArray as string).includes('data:')) {
      let objectURL = fileArray as string;
      let image = this.sanitizer.bypassSecurityTrustUrl(objectURL);
      return image;
    }
    let image = this.sanitizer.bypassSecurityTrustUrl(fileArray as string);
    return image;
  }

  handlePageEvent(e: PageEvent) {
    console.log(e);
    this.tableItem$ = this.menuItemService.getMenuItemsByRestaurantId(this.restuarantId,e.pageIndex+1,e.pageSize);
  }
}
