import { EventEmitter, Injectable } from '@angular/core';

import { BehaviorSubject, Observable, of, Subject } from 'rxjs';

import { DecimalPipe } from '@angular/common';
import { debounceTime, delay, map, switchMap, tap } from 'rxjs/operators';
import { SortColumn, SortDirection } from '../directives/NgbdSortableHeader';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { MenuItemExtraWithOptionsRequestModel } from '../tables/menu-item-extra-request';
import { MenuItemRequestModel } from '../tables/menu-item-request';
import { DiscountRequestModel } from '../tables/discountRequestModel';



interface SearchResult {
  tableItem: any[];
  total: number;
}

interface State {
  page: number;
  pageSize: number;
  searchTerm: string;
  sortColumn: SortColumn;
  sortDirection: SortDirection;
}

const compare = (v1: string | number, v2: string | number) => v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

function sort(tableItem: any[], column: SortColumn, direction: string): any[] {
    if (direction === '' || column === '') {
        return tableItem;
    } else {
        return [...tableItem].sort((a, b) => {
            const res = compare(a[column], b[column]);
            return direction === 'asc' ? res : -res;
        });
    }
}

@Injectable({
  providedIn: 'root'
})
export class DiscountService {

  baseUrl = environment.apiUrl;

  private _loading$ = new BehaviorSubject<boolean>(true);
  public RestaurantIdUpdated = new EventEmitter<string>();
  private _restuarantId$ = new BehaviorSubject<string>("");

  private _restuarants$ = new BehaviorSubject<any[]>([]);
    private _discounts$ = new BehaviorSubject<any[]>([]);
    private _discount$ = new BehaviorSubject<any>({});
    private _catergoryToEdit$ = new BehaviorSubject<any>({});
    private _total$ = new BehaviorSubject<number>(0);
    private catergoryToEdit : any;
    private discounts : any[];
    private category:any;    
    private _menuItem$ = new BehaviorSubject<any[]>([]);    
    private menuItems : any[];

    userData;

    private _state: State = {
        page: 1,
        pageSize: 10,
        searchTerm: '',
        sortColumn: '',
        sortDirection: ''
    };

    constructor(private pipe: DecimalPipe,private http: HttpClient) {

    }

    get total$() { return this._total$.asObservable(); }
    get loading$() { return this._loading$.asObservable(); }
    get page() { return this._state.page; }
    get pageSize() { return this._state.pageSize; }
    get searchTerm() { return this._state.searchTerm; }
    get categoryEditInitialize() {return new Observable<any>(observer => {
      observer.next({
        id: undefined,
        serviceProviderId: undefined,
        name: "",
        content: {
          value: 0,
          type: "%",
          isActive: true,
          startDate: undefined,
          endDate: undefined,
          itemsToExclude: [ ]
        }
      })
    });}  

    UpdateCatergoryObject(val : any)
    {
      return new Observable<any>(observer => {
         observer.next(val)
      });
    }

    UpdateMenuItem(val : any)
    {
      return new Observable<any>(observer => {
         observer.next(val)
      });
    }

    setRestuarantId(restaurantId:string)
    {
      this.RestaurantIdUpdated.emit(restaurantId);
      return this._restuarantId$.next(restaurantId);
    }

    getRestuarantId()
    {
      return this._restuarantId$;
    }

    completeId()
    {
      return this._restuarantId$.complete();
    }

  public getDiscountsForServiceProvider(restaurantId: string,pageNumber: number, pageSize: number){
    let params = new HttpParams();
    params = params.append('serviceProvderId', restaurantId);

    return this.http.get<any[]>(this.baseUrl + 'DiscountManagement/getdiscountbyserviceproviderid',{params}).pipe(
      tap((discounts: any[]) => {
        if (discounts) { 
          console.log('DiscountsGet..',discounts)
          this.discounts = discounts; 
           return this._discounts$.next(this.discounts);
        }
      })
    );
  }

  public getDiscountById(discountId:string){
    let params = new HttpParams();
    params = params.append('discountId', discountId);

    return this.http.get<any[]>(this.baseUrl + 'DiscountManagement/getdiscountbyid',{params}).pipe(
      tap((discount: any) => {
        if (discount) {  
          return this._discounts$.next(this.discounts);
        }
      })
    );
  }

  public createDiscount(discountToCreate: DiscountRequestModel){
    return this.http.post<any>(this.baseUrl + 'DiscountManagement/creatediscount',discountToCreate).pipe(
      tap((added: any) => { 
        if(added){
          this.discounts.push(discountToCreate)
          return this._discounts$.next(this.discounts);
        }
      })
    );
  }

  public editDiscount(categoryToEdit: DiscountRequestModel){
    return this.http.post<any>(this.baseUrl + 'DiscountManagement/editdiscount',categoryToEdit).pipe(
      tap((menuItemExtraAdded: any[]) => {
        if (menuItemExtraAdded) {  
          let indexToUpdate = this.discounts.findIndex(item => item.id === categoryToEdit.id);   
          this.discounts[indexToUpdate] = categoryToEdit;  
          this.discounts = Object.assign([], this.discounts);  
           return this._discounts$.next(this.discounts);
        }
      })
    );
  }

  public deleteDiscount(categoryToEdit: DiscountRequestModel){
    return this.http.post<any>(this.baseUrl + 'DiscountManagement/deletediscount',categoryToEdit).pipe(
      tap((menuItemExtraAdded: boolean) => {
        if (menuItemExtraAdded) { 
            let newdiscounts = this.discounts.filter(m => {return m.id !== categoryToEdit.id});
            this.discounts = Object.assign([], newdiscounts);        
            return this._discounts$.next(this.discounts);
        }
      })
    );
  }

  public getMenuItemsByRestaurantIdNoPaging(restaurantId: string){
    let params = new HttpParams();
    params = params.append('restaurantId', restaurantId);


    return this.http.get<any>(this.baseUrl + 'MenuManagement/getmenuitemsbyrestaurantidnopaging',{params}).pipe(
      tap((menuItems: any) => {
        console.log('API get Menu Items ');
        console.log(menuItems.data);
        if (menuItems) {    
          this.menuItems = menuItems;      
           return this._menuItem$.next(menuItems);
        }
      })
    );
  }
}
