import { DecimalPipe } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { PageEvent } from "@angular/material/paginator";
import { Observable, map } from "rxjs";
import { CategoryService } from "src/app/shared/service/category.service";
import { OrderService } from "src/app/shared/service/order.service";
import { saveAs } from "file-saver";
import { AccountService } from "src/app/shared/service/account.service";

@Component({
  selector: "app-order-history",
  templateUrl: "./order-history.component.html",
  styleUrls: ["./order-history.component.scss"],
  providers: [OrderService, DecimalPipe, CategoryService],
})
export class OrderHistoryComponent implements OnInit {
  restuarantId = "";
  orders$: Observable<any[]>;
  orderFromDate: string;
  orderToDate: string;

  constructor(
    public orderService: OrderService,
    public categoryService: CategoryService,
    private accountService: AccountService
  ) {}

  async ngOnInit() {
    const user = this.accountService.getLoggedInUser();
    this.restuarantId = user.restaurantId;
    this.orders$ = this.orderService.getAllOrders(user.restaurantId, 1, 10);
  }

  loadCurrentUser() {
    const token = localStorage.getItem("token");
    this.accountService.loadCurrentUser(token).subscribe(
      () => {},
      (error) => {}
    );
  }

  handlePageEvent(e: PageEvent) {
    console.log(e);
    this.orders$ = this.orderService.getAllOrders(
      this.restuarantId,
      e.pageIndex + 1,
      e.pageSize
    );
  }

  getReport() {
    this.orderService
      .getOrderReportByRestaurantId(
        this.restuarantId,
        this.orderFromDate,
        this.orderToDate
      )
      .subscribe((res) => {
        if (res.content && res.content.size) {
          saveAs(res.content, "OrderReport.csv");
        }
      });
  }
}
