import { DecimalPipe } from "@angular/common";
import {
  AfterViewInit,
  Component,
  EventEmitter,
  QueryList,
  ViewChild,
  ViewChildren,
} from "@angular/core";
import { PageEvent } from "@angular/material/paginator";
import { DomSanitizer } from "@angular/platform-browser";
import { Observable } from "rxjs";
import { DiscountManagementModalComponent } from "src/app/shared/components/discount-management-modal/discount-management-modal.component";
import { NgbdSortableHeader } from "src/app/shared/directives/NgbdSortableHeader";
import { AccountService } from "src/app/shared/service/account.service";
import { DiscountService } from "src/app/shared/service/discount.service";
import { TableService } from "src/app/shared/service/table.service";
import { CategoryRequestModel } from "src/app/shared/tables/catergory-request";
import { DiscountRequestModel } from "src/app/shared/tables/discountRequestModel";

@Component({
  selector: "app-discount-management-list",
  templateUrl: "./discount-management-list.component.html",
  styleUrls: ["./discount-management-list.component.scss"],
  providers: [TableService, DecimalPipe],
})
export class DiscountManagementListComponent {
  searchText;
  tableItem$: Observable<any[]>;
  total$: Observable<number>;
  discountToDelete$: Observable<DiscountRequestModel> =
    new Observable<DiscountRequestModel>();
  restuarantId$: Observable<string>;
  restuarantId: string = "";
  public restuarants$: Observable<any[]>;

  public selectedId: EventEmitter<string> = new EventEmitter<string>();
  @ViewChild("discountModal")
  private modalComponent!: DiscountManagementModalComponent;

  selectedCategoryId: string;

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  constructor(
    public discountService: DiscountService,
    private sanitizer: DomSanitizer,
    private accountService: AccountService
  ) {
    const user = this.accountService.getLoggedInUser();
    this.restuarantId = user.restaurantId;
    this.tableItem$ = this.discountService.getDiscountsForServiceProvider(
      user.restaurantId,
      1,
      10
    );

    // this.menuItemService.setUserData(CATEGORY)
  }

  selectRestuarant(event: any) {
    console.log(event);
    this.discountService.setRestuarantId(event);
  }

  editCategory(selectedId: string) {
    this.selectedCategoryId = selectedId;
    this.selectedId.emit(selectedId);
    this.modalComponent.open();
  }

  deleteCategory(categoryId: string) {
    this.discountToDelete$ = this.discountService.getDiscountById(categoryId);
    this.discountToDelete$.subscribe((c) =>
      this.discountService.deleteDiscount(c).subscribe(() =>{window.location.reload()})
    );
  }

  handlePageEvent(e: PageEvent) {
    console.log(e);
    this.tableItem$ = this.discountService.getDiscountsForServiceProvider(
      this.restuarantId,
      e.pageIndex + 1,
      e.pageSize
    );
  }
}
