import { EventEmitter, Injectable } from '@angular/core';

import { BehaviorSubject, Observable, of, Subject } from 'rxjs';

import { DecimalPipe } from '@angular/common';
import { debounceTime, delay, map, switchMap, tap } from 'rxjs/operators';
import { SortColumn, SortDirection } from '../directives/NgbdSortableHeader';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { MenuItemExtraWithOptionsRequestModel } from '../tables/menu-item-extra-request';
import { MenuItemRequestModel } from '../tables/menu-item-request';
import { CategoryRequestModel } from '../tables/catergory-request';



interface SearchResult {
  tableItem: any[];
  total: number;
}

interface State {
  page: number;
  pageSize: number;
  searchTerm: string;
  sortColumn: SortColumn;
  sortDirection: SortDirection;
}

const compare = (v1: string | number, v2: string | number) => v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

function sort(tableItem: any[], column: SortColumn, direction: string): any[] {
    if (direction === '' || column === '') {
        return tableItem;
    } else {
        return [...tableItem].sort((a, b) => {
            const res = compare(a[column], b[column]);
            return direction === 'asc' ? res : -res;
        });
    }
}

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  baseUrl = environment.apiUrl;

  private _loading$ = new BehaviorSubject<boolean>(true);
  public RestaurantIdUpdated = new EventEmitter<string>();
  private _restuarantId$ = new BehaviorSubject<string>("");

  private _restuarants$ = new BehaviorSubject<any[]>([]);
    private _catergories$ = new BehaviorSubject<any>({});
    private _catergory$ = new BehaviorSubject<any>({});
    private _catergoryToEdit$ = new BehaviorSubject<any>({});
    private _total$ = new BehaviorSubject<number>(0);
    private catergoryToEdit : any;
    private categories : any;
    private category:any;

    userData;

    private _state: State = {
        page: 1,
        pageSize: 10,
        searchTerm: '',
        sortColumn: '',
        sortDirection: ''
    };

    constructor(private pipe: DecimalPipe,private http: HttpClient) {

    }

    get total$() { return this._total$.asObservable(); }
    get loading$() { return this._loading$.asObservable(); }
    get page() { return this._state.page; }
    get pageSize() { return this._state.pageSize; }
    get searchTerm() { return this._state.searchTerm; }
    get categoryEditInitialize() {return new Observable<any>(observer => {
      observer.next({
        id: undefined,
        categoryName: undefined,
        index: 0,
        restaurantId: undefined,
        isActive: true,
        promo: {
            isTimePromo: false,
            isRepeating: false,
            startDate: undefined,
            endDate: undefined
        },
        printerDetails: undefined
    })
    });}  

    UpdateCatergoryObject(val : any)
    {
      return new Observable<any>(observer => {
         observer.next(val)
      });
    }

    UpdateMenuItem(val : any)
    {
      return new Observable<any>(observer => {
         observer.next(val)
      });
    }

    setRestuarantId(restaurantId:string)
    {
      this.RestaurantIdUpdated.emit(restaurantId);
      return this._restuarantId$.next(restaurantId);
    }

    getRestuarantId()
    {
      return this._restuarantId$;
    }

    completeId()
    {
      return this._restuarantId$.complete();
    }

    public getAllRestuarants(){
      let params = new HttpParams();
  
      return this.http.get<any[]>(this.baseUrl + 'RestaurantManagement/getallrestaurants').pipe(
        tap((restuarants: any[]) => {
          if (restuarants) {         
             return this._restuarants$.next(restuarants);
          }
        })
      );
    }

  public getCategoriesForRestaurant(restaurantId: string,pageNumber: number, pageSize: number){
    let params = new HttpParams();
    params = params.append('restuarantId', restaurantId);

    if (pageNumber !== null && pageSize !== null) {
      params = params.append('pageNumber', pageNumber.toString());
      params = params.append('pageSize', pageSize.toString());
    }
    return this.http.get<any>(this.baseUrl + 'RestaurantManagement/getrestaurantcategories',{params}).pipe(
      tap((categories: any) => {
        if (categories) {  
          this.categories = categories;         
           return this._catergories$.next(categories);
        }
      })
    );
  }

  
  public getCategoriesForRestaurantNoPaging(restaurantId: string){
    let params = new HttpParams();
    params = params.append('restuarantId', restaurantId);

    return this.http.get<any[]>(this.baseUrl + 'RestaurantManagement/getrestaurantcategoriesnopaging',{params}).pipe(
      tap((categories: any[]) => {
        if (categories) {         
           return this._catergories$.next(categories);
        }
      })
    );
  }

  public getCategoryById(categoryId:string){
    let params = new HttpParams();
    params = params.append('categoryId', categoryId);

    return this.http.get<any[]>(this.baseUrl + 'RestaurantManagement/getrestaurantcategorybyid',{params}).pipe(
      tap((category: any) => {
        if (category) {         
           return this._catergory$.next(category);
        }
      })
    );
  }

  public createCategory(categoryToCreate: CategoryRequestModel){
    return this.http.post<any>(this.baseUrl + 'RestaurantManagement/addrestaurantcategory',categoryToCreate).pipe(
      tap((added: any) => {
        if (added) { 
          this.categories.data.push(categoryToCreate)         
          return this._catergories$.next(this.categories);
        }
      })
    );
  }

  public editCategory(categoryToEdit: CategoryRequestModel){
    return this.http.post<any>(this.baseUrl + 'RestaurantManagement/editrestaurantcategory',categoryToEdit).pipe(
      tap((menuItemExtraAdded: any[]) => {
        if (menuItemExtraAdded) {  
          let indexToUpdate = this.categories.data.findIndex(item => item.id === categoryToEdit.id);   
          this.categories.data[indexToUpdate] = categoryToEdit;  
          this.categories.data = Object.assign([], this.categories.data);  
           return this._catergories$.next(this.categories);
        }
      })
    );
  }

  public updateCategoryIndexes(categories: Array<CategoryRequestModel>){
    return this.http.post<any>(this.baseUrl + 'RestaurantManagement/updatecategoryindexes',categories).pipe(
      tap((menuItemExtraAdded: any[]) => {
        // if (menuItemExtraAdded) { 
        //   this.tableItems.push(menuItemExtraAdded)         
        //    return this._tableItem$.next(this.tableItems);
        // }
      })
    );
  }

  public deleteCategory(categoryToEdit: CategoryRequestModel){
    return this.http.post<any>(this.baseUrl + 'RestaurantManagement/deleterestaurantcategory',categoryToEdit).pipe(
      tap((menuItemExtraAdded: any[]) => {
        if (menuItemExtraAdded) { 
          this.categories.data = this.categories.data.filter(m => {return m.id !== categoryToEdit.id});      
          return this._catergories$.next(this.categories);
        }
      })
    );
  }
}
