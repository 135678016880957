import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PrintService {

  constructor() { }

  printReceipt(receipt: any) {
   window['electron'].printReceipt(receipt);
  }
}
