import { Component, OnInit } from '@angular/core';
import { AccountService } from './shared/service/account.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'multikart-backend';

  constructor(private accountService: AccountService) {
    
  }

  ngOnInit(): void {
    // this.loadCurrentUser();
  }


  loadCurrentUser() {
    const token = localStorage.getItem('token');
    this.accountService.loadCurrentUser(token).subscribe(() => {
    }, error => {
    });
  }
}
