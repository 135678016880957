import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  OnInit,
  QueryList,
  SimpleChange,
  ViewChild,
  ViewChildren,
} from "@angular/core";
import { MenuItemExtraWithOptionsRequestModel } from "../../tables/menu-item-extra-request";
import { MenuItemExtraOptionRequest } from "../../tables/menu-item-extra-option-request";
import {
  ModalDismissReasons,
  NgbModal,
  NgbModalRef,
} from "@ng-bootstrap/ng-bootstrap";
import { MenuItemsService } from "../../service/menu.items.service";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { BehaviorSubject, Observable, Subject, observable } from "rxjs";
import { v4 as uuid } from "uuid";
import { MenuItemExtra } from "../../tables/menu-item-extra";
import { MenuItemRequestModel } from "../../tables/menu-item-request";
import { NgbdSortableHeader } from "../../directives/NgbdSortableHeader";
import { DomSanitizer } from "@angular/platform-browser";
import { CategoryService } from "../../service/category.service";
import { FormControl } from "@angular/forms";
import sanityClient from "@sanity/client";
import { environment } from "src/environments/environment";
import { AccountService } from "../../service/account.service";

const client = sanityClient({
  projectId: "wl9gemnw", // find this at manage.sanity.io or in your sanity.json
  dataset: "production", // or the name you chose in step 1
  useCdn: true, // `false` if you want to ensure fresh data
  token: environment.NEXT_PUBLIC_SANITY_TOKEN,
});

@Component({
  selector: "app-menu-item-modal",
  templateUrl: "./menu-item-modal.component.html",
  styleUrls: ["./menu-item-modal.component.scss"],
})
export class MenuItemModalComponent implements AfterViewInit, OnInit {
  menuItemExtras$: Observable<MenuItemExtraWithOptionsRequestModel[]>;
  menuItemImage: any;

  @ViewChild("menuItemExtrasModal") menuItemExtrasModal: NgbModalRef;
  @ViewChild("menuItemModal") menuItemModal: NgbModalRef;
  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  @Input() addOrEdit: string;
  @Input() menuItemId: Observable<string> = new Observable<string>();

  public closeResult: string;
  searchText;
  total$: Observable<number>;

  menuItem$: Observable<MenuItemRequestModel> =
    new Observable<MenuItemRequestModel>();

  menuItemExtra: any = {};

  public _menuItemExtras = new BehaviorSubject<any[]>([]);
  menuItemExtraWithOptions: MenuItemExtraWithOptionsRequestModel = {
    name: "",
    description: "",
    isRequired: false,
    menuItemExtraId: "",
    assignedExtraIndex: 0,
    menuItemExtraOptions: [],
    optionsLimit: 1,
    restuarantId: "",
  };
  menuItemExtraWithOptionsToAdd: MenuItemExtraWithOptionsRequestModel[] = [];

  selected = "option2";
  public menuCategories$: Observable<any[]>;
  public menuCategories: Array<any> = [];
  public _menuCategories = new BehaviorSubject<any[]>([]);

  restaurantId = "";

  constructor(
    private modalService: NgbModal,
    public menuItemService: MenuItemsService,
    public categoryService: CategoryService,
    private sanitizer: DomSanitizer,
    private accountService: AccountService
  ) {
    const user = this.accountService.getLoggedInUser();
    this.restaurantId = user.restaurantId;
    this.menuCategories$ = categoryService.getCategoriesForRestaurantNoPaging(
      user.restaurantId
    );
    this.menuItemExtras$ =
      menuItemService.getAllMenuItemsExtrasWithOptionsByRestaurantId(
        user.restaurantId
      );

    this.menuItem$ = menuItemService.menuItemToEditInitialize;

    if (this._menuItemExtras.value.length > 0) {
      this.menuItemExtras$.subscribe((v) => {
        return this._menuItemExtras.value.forEach((e) =>
          v.filter(
            (m) => (e.isChecked = m.menuItemExtraId !== e.menuItemExtraId)
          )
        );
      });
    }

    this.menuItemExtra.menuItemExtraOptions =
      new Array<MenuItemExtraOptionRequest>();
  }
  ngOnInit(): void {
    // this.menuItemExtraId.subscribe(m => {if(m.length>0){
    //   this.menuItemExtra$ = this.menuItemService.getMenuItemExtraWithOptions(m);
    // }});
  }

  isUsed(menuItemExtra: MenuItemExtraWithOptionsRequestModel) {
    if (this._menuItemExtras.value.includes(menuItemExtra)) {
      console.log(this._menuItemExtras.value.includes(menuItemExtra));
    }
    return (
      this._menuItemExtras.value.filter((f) => {
        return f.menuItemExtraId === menuItemExtra.menuItemExtraId;
      }).length > 0
    );
  }

  open() {
    this.modalService
      .open(this.menuItemModal, { ariaLabelledBy: "modal-basic-title" })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }
  openMenuExtraOption(content) {
    this._menuItemExtras.next(this._menuItemExtras.value);
    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title" })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  drop(event: CdkDragDrop<string[]>) {
    this._menuItemExtras.value.forEach((v) => {
      return (v.menuItemExtraOptionEdited = true);
    });
    moveItemInArray(
      this._menuItemExtras.value,
      event.previousIndex,
      event.currentIndex
    );
  }

  addExtraToArray() {
    console.log(this.menuItemExtraWithOptionsToAdd);
    this._menuItemExtras.next(this.menuItemExtraWithOptionsToAdd);
  }

  deleteExtraToArray(extraOption: MenuItemExtraWithOptionsRequestModel) {
    this._menuItemExtras.next(
      this._menuItemExtras.value.filter((v) => {
        return v.menuItemExtraId !== extraOption.menuItemExtraId;
      })
    );
  }

  createMenuItemExtraWithOptions() {
    this.menuItem$.subscribe((v) => {
      let menuItemWithExtraAndOptionsToAddOrUpdate = this.addAndIndexExtras(v);
      menuItemWithExtraAndOptionsToAddOrUpdate.restuarantId = this.restaurantId;
      if (menuItemWithExtraAndOptionsToAddOrUpdate.menuItemId === undefined) {
        menuItemWithExtraAndOptionsToAddOrUpdate.menuItemId = uuid();
        this.menuItemService
          .createMenuItemWithExtraAndOptions(
            menuItemWithExtraAndOptionsToAddOrUpdate
          )
          .subscribe((res) => {});
      } else {
        this.menuItemService
          .editAndAssignMenuItemExtrasAndOptions(
            menuItemWithExtraAndOptionsToAddOrUpdate
          )
          .subscribe((res) => {});
      }

      console.log(menuItemWithExtraAndOptionsToAddOrUpdate);
    });
  }

  addAndIndexExtras(menuItemWithExtraWithOptions: MenuItemRequestModel) {
    //index options based on array position
    this._menuItemExtras.value.forEach((o) => {
      o.assignedExtraIndex = this._menuItemExtras.value.indexOf(o);
    });
    menuItemWithExtraWithOptions.menuItemExtraWithOptions =
      this._menuItemExtras.value;

    return menuItemWithExtraWithOptions;
  }

  changeMenuItem(event: any) {
    this.menuItem$ = this.menuItemService.UpdateMenuItem(event);
  }

  onChange(event: any) {
    console.log("Toggle changed to: ", event.checked);
  }

  isChecked(menuItemExtra: any) {
    if (
      this.menuItemExtraWithOptionsToAdd.filter((f) => {
        return f.menuItemExtraId === menuItemExtra.menuItemExtraId;
      }).length > 0
    ) {
      console.log(
        this.menuItemExtraWithOptionsToAdd.filter((v) => {
          return v.menuItemExtraId !== menuItemExtra.menuItemExtraId;
        })
      );
      this.menuItemExtraWithOptionsToAdd =
        this.menuItemExtraWithOptionsToAdd.filter((v) => {
          return v.menuItemExtraId !== menuItemExtra.menuItemExtraId;
        });
    } else {
      this.menuItemExtraWithOptionsToAdd.push(menuItemExtra);
    }
  }

  onSort({ column, direction }) {
    // resetting other headers
    console.log("dsafsa");

    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = "";
      }
    });

    this.menuItemService.sortColumn = column;
    this.menuItemService.sortDirection = direction;
  }

  async readUrl(event: any, menuItem: any) {
    if (event.target.files.length === 0) return;
    //Image upload validation
    var mimeType = event.target.files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    // Image upload
    console.log(event.target.files[0]);
    try {
      // Step 1: Upload the image asset
      const imageAsset = await client.assets.upload(
        "image",
        event.target.files[0],
        {
          contentType: event.target.files[0].type,
          filename: uuid(),
        }
      );
      var imageTitle = uuid().toString();
      // Step 2: Create a document using the image asset's _id
      const doc = {
        _type: "qFlowImage",
        title: imageTitle,
        qFlowImage: {
          _type: "image",
          asset: {
            _type: "reference",
            _ref: imageAsset._id,
          },
        },
      };

      const createdDoc = client.create(doc).then((docCreated) => {
        client
          .fetch(
            `*[_type == 'qFlowImage'&& title == '` +
              imageTitle +
              `'][0]{
          title,
          "imageUrl": qFlowImage.asset->url
        }`
          )
          .then((image) => {
            menuItem.menuItemImage = image.imageUrl;
            this.menuItem$ = this.menuItemService.UpdateMenuItem(menuItem);
          });
      });

      console.log(createdDoc);
    } catch (error) {
      console.error("Upload failed:", error.message);
    }
  }

  public getImage(fileArray: string) {
    console.log(fileArray);
    if (!(fileArray as string).includes("data:")) {
      let objectURL = fileArray as string;
      let image = this.sanitizer.bypassSecurityTrustUrl(objectURL);
      return image;
    }
    let image = this.sanitizer.bypassSecurityTrustUrl(fileArray as string);
    return image;
  }

  ngAfterViewInit() {
    this.menuItemId.subscribe((m) => {
      if (m.length > 0) {
        this.menuItem$ =
          this.menuItemService.getMenuItemWithExtrasAndOptions(m);
        this.menuItem$.subscribe((v) => {
          console.log(v.menuItemExtraWithOptions);
          this.menuItemExtraWithOptionsToAdd = v.menuItemExtraWithOptions;
          this._menuItemExtras.next(v.menuItemExtraWithOptions);
        });
      } else {
        this.menuItem$ = this.menuItemService.menuItemToEditInitialize;
      }
    });
  }

  clearState() {
    this._menuItemExtras.unsubscribe();
    this._menuItemExtras = new BehaviorSubject<any[]>([]);
  }
}
