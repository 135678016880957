import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { UserService } from '../../../shared/service/user.service'; 
import { LoginRequestDTO } from 'src/app/shared/tables/loginRequestDTO';
import { AccountService } from 'src/app/shared/service/account.service';
import { Router } from '@angular/router';
import { Server } from 'http';

declare var emailjs: any;


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  providers: [AccountService],
})
export class LoginComponent implements OnInit {

  @ViewChild('restaurantName') restaurantName: ElementRef;
  @ViewChild('contactName') contactName: ElementRef;
  @ViewChild('contactNumber') contactNumber: ElementRef;
  @ViewChild('emailAddress') emailAddress: ElementRef;

  public loginForm: UntypedFormGroup;
  public registerForm: UntypedFormGroup;
  public active = 1;  
  public loading = false;

  constructor(private formBuilder: UntypedFormBuilder, private accountService: AccountService, private router: Router) {
    this.createLoginForm();
    this.createRegisterForm();
  }

  // Login //
  createLoginForm() {
    this.loginForm = this.formBuilder.group({
      email: [''],
      password: [''],
    })
  }


  login() {
    this.loading = true;
    this.accountService.login(this.loginForm.value).subscribe(
      response => {        
        console.log(response);
        this.loading = false;
      },
      error => {
        console.log('Login Data', this.loginForm.value);
        console.error('Login failed', error);
        this.loading = false;

      }
    );
  }

  // Registration //
  createRegisterForm() {
    this.registerForm = this.formBuilder.group({
      Email: [''],
      Name: [''],
      Password: [''],
      Role: ['admin']
    })
  }

  register() {
    this.accountService.register(this.registerForm.value).subscribe(
      response => {
        console.log('Registration successful', response);
      },
      error => {
        console.log('Register Data', this.registerForm.value);
        console.error('Registration failed', error);
      }
    );
  }

  sendEmail(event: Event) {
    event.preventDefault();

    const serviceID = 'service_2t4ov4x';
    const templateID = 'template_umvoqlj';
    const userID = 'mdZb2pgScuGxjbLz1';
    const templateParams = {
      restaurant_name: this.restaurantName.nativeElement.value,
      contact_name: this.contactName.nativeElement.value,
      contact_number: this.contactNumber.nativeElement.value,
      email: this.emailAddress.nativeElement.value,
    };

    emailjs.send(serviceID, templateID, templateParams, userID)
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text);
        // Optionally, clear the form or provide user feedback
      }, (error) => {
        console.log('FAILED...', error);
        // Optionally, provide error feedback to the user
      });
  }
  

  ngOnInit(): void {
  }

}
