<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="row">
        <button type="button" class="btn btn-primary" (click)="printReceipt()" style="margin-left: 10px;">Prin test</button>   
        <ng-container *ngIf="signalRService.data">
            <div class="col-xl-6 xl-100" *ngFor = 'let order of signalRService.data'>
                <div class="card height-equal">
                    <div class="card-header" [ngClass]="[order.orderStatus === 'accepted'?'bg-warning':order.orderStatus === 'new'?'bg-secondary':'bg-primary']">
                        <h5>{{order.orderId}}</h5>
                        <h6 style="color: black;">Customer name: {{order.orderContent.customerName ?? 'No Customer Name'}}</h6>
                        <h6 style="color: black;">Order notes: {{order.orderContent.orderNotes ?? 'No Notes Given'}}</h6>
                        <h6 style="color: black;">Room number: {{order.orderContent.roomNumber ?? 'No Room Number Given'}}</h6>
                        <h6 style="color: black;">Order collect time: {{order.orderContent.orderCollectTime ?? 'Now'}}</h6>
                        
                    </div>
                    
                        <div class="card-body" [ngClass]="[order.orderStatus === 'accepted'?'bg-warning':order.orderStatus === 'new'?'bg-secondary':'bg-primary']">
                            <div class="user-status table-responsive latest-order-table">
                                <table class="table table-bordernone">
                                    <thead>
                                        <tr>
                                            <th scope="col">Name</th>
                                            <th scope="col">Price</th>
                                            <th scope="col">Selected Options</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor = 'let basketItem of order.orderContent.basketItems'>
                                            <td>{{ basketItem.name }}</td>
                                            <td class="digits">R{{ basketItem.price }}</td>
                                            <td >
                                                <ng-container *ngFor = 'let option of basketItem.selectedOptions'>
                                                    {{option.name}}
                                                </ng-container>
                                            </td>
                                        </tr>                                    
                                    </tbody>
                                </table>
                                <a href="javascript:void(0)" class="btn btn-primary" *ngIf="order.orderStatus === 'new'" (click)="updateOrderStatus('accepted',order)">Accept Order</a>
                                <a href="javascript:void(0)" class="btn btn-primary" *ngIf="order.orderStatus === 'accepted'" (click)="updateOrderStatus('completed',order)">Complete Order</a>
                            </div>
                        </div>
                    
                </div>
            </div>
        </ng-container>
    </div>
</div>
<!-- Container-fluid Ends-->