<div id="batchDelete" class="category-table custom-datatable">
    <ng-container *ngIf="tableItem$ | async as searchRes">
    <div class="table-responsive">
        <!-- <form>
            <div class="mb-3 row">
                <label for="table-complete-search" class="col-xs-3 col-sm-auto col-form-label">Full text search:</label>
                <div class="col-xs-3 col-sm-auto">
                  <input id="table-complete-search" type="text" class="form-control" name="searchTerm"  [(ngModel)]="searchText" autocomplete="off">
                </div>
            </div>
          </form> -->
        <table class="table table-striped">
            <thead>
            <tr>
                <th scope="col" sortable="product_name" (sort)="onSort($event)">Name</th>                
                <th scope="col">Actions</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor = 'let serviceProviderGroup of searchRes | filter:searchText'>
            <!-- <td>
                <img [src]="getImage(menuItem.menuItemImage)" class="imgTable" style="width: 20px">
            </td> -->
            <td>{{ serviceProviderGroup.name }}</td>
            <td>
                <a href="javascript:void(0)" (click)="editRestaurant(serviceProviderGroup.id)"><i class='fa fa-edit f-12'></i></a>&nbsp;
                <a href="javascript:void(0)" (click)="deleteRestaurant(serviceProviderGroup.id)"><i class="fa fa-trash-o"></i></a>
            </td>
            </tr>
            </tbody>
        </table>
        <div class="d-flex justify-content-center p-2">
            <mat-paginator #paginator
               class="demo-paginator"
               (page)="handlePageEvent($event)"
               [length]="searchRes.totalCount"
               [pageSize]="searchRes.pageSize"
               [disabled]="false"
               [showFirstLastButtons]="true"
               [pageSizeOptions]="false"
               [hidePageSize]="false"
               [pageIndex]="searchRes.currentPage - 1"
               aria-label="Select page">
            </mat-paginator>
         </div> 
    </div>
    
</ng-container>
</div>
<app-service-provider-group-modal #restaurantModal [addOrEdit]="'Edit'" [restaurantId]="selectedId"></app-service-provider-group-modal>
<app-admin-user-create-modal #adminUserCreateModal [restaurantId]="selectedId" [restaurantName]="selectedRestaurantName"></app-admin-user-create-modal>