import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  OnInit,
  QueryList,
  SimpleChange,
  ViewChild,
  ViewChildren,
} from "@angular/core";
import { MenuItemExtraWithOptionsRequestModel } from "../../tables/menu-item-extra-request";
import { MenuItemExtraOptionRequest } from "../../tables/menu-item-extra-option-request";
import {
  ModalDismissReasons,
  NgbModal,
  NgbModalRef,
} from "@ng-bootstrap/ng-bootstrap";
import { MenuItemsService } from "../../service/menu.items.service";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { BehaviorSubject, Observable, Subject, observable } from "rxjs";
import { v4 as uuid } from "uuid";
import { MenuItemExtra } from "../../tables/menu-item-extra";
import { MenuItemRequestModel } from "../../tables/menu-item-request";
import { NgbdSortableHeader } from "../../directives/NgbdSortableHeader";
import { DomSanitizer } from "@angular/platform-browser";
import { CategoryService } from "../../service/category.service";
import { FormControl } from "@angular/forms";
import sanityClient from "@sanity/client";
import { environment } from "src/environments/environment";
import { AccountService } from "../../service/account.service";
import { IRegister } from "../../models/registerModel";
import { PaymentDetails } from "../../tables/paymentDetails";
import { RestaurantService } from "../../service/restaurant.service";

const client = sanityClient({
  projectId: "wl9gemnw", // find this at manage.sanity.io or in your sanity.json
  dataset: "production", // or the name you chose in step 1
  useCdn: true, // `false` if you want to ensure fresh data
  token: environment.NEXT_PUBLIC_SANITY_TOKEN,
});

@Component({
  selector: "app-admin-user-create-modal",
  templateUrl: "./admin-user-create-modal.component.html",
  styleUrls: ["./admin-user-create-modal.component.scss"],
})
export class AdminUserCreateModalComponent implements AfterViewInit, OnInit {
  @ViewChild("adminUserCreate") adminUserCreateModal: NgbModalRef;
  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  @Input() restaurantId: Observable<string> = new Observable<string>();
  @Input() restaurantName: Observable<string> = new Observable<string>();

  restaurantIdLocal: string = "";
  restaurantNameLocal: string = "";
  public closeResult: string;
  searchText;
  total$: Observable<number>;

  user$: Observable<IRegister> = new Observable<IRegister>();
  paymentDetails$: Observable<PaymentDetails> = new Observable<PaymentDetails>();

  menuItemExtra: any = {};

  constructor(
    private modalService: NgbModal,
    public accountService: AccountService,
    public restaurantService: RestaurantService,
    private sanitizer: DomSanitizer
  ) {}
  ngOnInit(): void {
    // this.menuItemExtraId.subscribe(m => {if(m.length>0){
    //   this.menuItemExtra$ = this.menuItemService.getMenuItemExtraWithOptions(m);
    // }});
  }

  open() {
    this.modalService
      .open(this.adminUserCreateModal, { ariaLabelledBy: "modal-basic-title" })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  createUser() {
    this.user$.subscribe((u) => {
      u.restaurantId = this.restaurantIdLocal;
      u.restaurantName = this.restaurantNameLocal;
      this.accountService.register(u).subscribe((v) => {
        v;
      });
    });
  }

  createPaymentDetails() {
    this.paymentDetails$.subscribe((u) => {      
      u.serviceProviderId = this.restaurantIdLocal;
      if (u.id === '00000000-0000-0000-0000-000000000000') {
        u.id = uuid();
        
      this.restaurantService.addPaymentDetails(u).subscribe((v) => {
        v;
      });
      }
      else{
        this.restaurantService.editPaymentDetails(u).subscribe((v) => {
          v;
        });
      }
    });
  }

  changeMenuItem(event: any) {
    this.user$ = this.accountService.UpdateUser(event);
  }

  changePaymentDetails(event: any) {
    this.paymentDetails$ = this.restaurantService.UpdatePaymentDetails(event);
  }

  onChange(event: any) {
    console.log("Toggle changed to: ", event.checked);
  }

  ngAfterViewInit() {
    this.restaurantId.subscribe((m) => {
      this.restaurantIdLocal = m;
      if (m.length > 0) {
        this.user$ = this.accountService.getCurrentRestaurantUserInfo(m);
        this.paymentDetails$ = this.restaurantService.getPaymentDetails(m);
      } else {
        this.user$ = this.accountService.userEditInitialize;
        this.paymentDetails$ = this.restaurantService.restaurantToEditInitialize;
      }
    });
    this.restaurantName.subscribe((m) => {
      this.restaurantNameLocal = m;
    });
  }

  clearState() {}
}
