import {
  Component,
  EventEmitter,
  QueryList,
  ViewChild,
  ViewChildren,
} from "@angular/core";
import { PageEvent } from "@angular/material/paginator";
import { DomSanitizer } from "@angular/platform-browser";
import { Observable } from "rxjs";
import { MenuItemModalComponent } from "src/app/shared/components/menu-item-modal/menu-item-modal.component";
import { NgbdSortableHeader } from "src/app/shared/directives/NgbdSortableHeader";
import { AccountService } from "src/app/shared/service/account.service";
import { CategoryService } from "src/app/shared/service/category.service";
import { MenuItemsService } from "src/app/shared/service/menu.items.service";
import { TableService } from "src/app/shared/service/table.service";
import { CATEGORY, Category } from "src/app/shared/tables/category";

@Component({
  selector: "app-menu-item-list",
  templateUrl: "./menu-item-list.component.html",
  styleUrls: ["./menu-item-list.component.scss"],
})
export class MenuItemListComponent {
  searchText;
  tableItem$: Observable<any>;
  total$: Observable<number>;
  restuarantId: string = "";

  public selectedId: EventEmitter<string> = new EventEmitter<string>();
  @ViewChild("menuItemModal") private modalComponent!: MenuItemModalComponent;

  selectedMenuItemId: string;

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  constructor(
    public menuItemService: MenuItemsService,
    private sanitizer: DomSanitizer,
    public categoryService: CategoryService,
    private accountService: AccountService
  ) {
    const user = this.accountService.getLoggedInUser();
    this.restuarantId = user.restaurantId;
    this.tableItem$ = menuItemService.getMenuItemsByRestaurantId(
      user.restaurantId,
      1,
      10
    );
    this.tableItem$.subscribe((res) => console.log(res));
    this.total$ = menuItemService.total$;
    this.menuItemService.setUserData(CATEGORY);
  }

  onSort({ column, direction }) {
    // resetting other headers
    console.log("dsafsa");

    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = "";
      }
    });

    this.menuItemService.sortColumn = column;
    this.menuItemService.sortDirection = direction;
  }

  editMenuItem(selectedId: string) {
    this.selectedMenuItemId = selectedId;
    this.selectedId.emit(selectedId);
    this.modalComponent.open();
  }

  deleteMenuItem(menuItemId: string) {
    this.menuItemService.deleteMenuItem(menuItemId).subscribe(()=>{
    });
  }

  public getImage(fileArray: string) {
    if (!(fileArray as string).includes("data:")) {
      let objectURL = fileArray as string;
      let image = this.sanitizer.bypassSecurityTrustUrl(objectURL);
      return image;
    }
    let image = this.sanitizer.bypassSecurityTrustUrl(fileArray as string);
    return image;
  }

  handlePageEvent(e: PageEvent) {
    console.log(e);
    this.tableItem$ = this.menuItemService.getMenuItemsByRestaurantId(
      this.restuarantId,
      e.pageIndex + 1,
      e.pageSize
    );
  }
}
