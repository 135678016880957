import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { DiscountManagementComponent } from './discount-management.component';

const routes: Routes = [
  { 
   path: '',
    children: [
      {
        path: 'default',
        component: DiscountManagementComponent,
        data: {
          title: "Discount Management",
          breadcrumb: "Discount Management"
        }
      }
    ],
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class DiscountManagementRoutingModule { }
