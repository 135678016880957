<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header">
                    <h5>Discount Management</h5>
                </div>
                <div class="card-body">
                    <div class="btn-popup pull-right">
                        <button type="button" class="btn btn-primary" data-toggle="modal" data-original-title="test"
                            data-target="#exampleModal" (click)="open()">Add Discount</button>                   
                    </div>
                    <app-discount-management-list></app-discount-management-list>
                </div>
            </div>
        </div>
    </div>
</div>
<app-discount-management-modal #discountModal [addOrEdit]="'Add'" [categoryId]="selectedId"></app-discount-management-modal>