import { Injectable } from "@angular/core";
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree,
} from "@angular/router";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AccountService } from "../shared/service/account.service";

@Injectable({
  providedIn: "root",
})
export class AuthGuard {
  constructor(private accountService: AccountService, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    const user = this.accountService.getLoggedInUser();
    console.log(user);
    var urlSegment = next.url as Array<UrlSegment>;
    if (user.token === null) {
      this.router.navigate(["/auth/login"]);
    }
    // if(auth !== null && auth.role === 'SuperAdmin')
    // {
    //   this.router.navigate(['/restaurant-management/default'], { queryParams: { returnUrl: state.url } });
    // }
    // if (auth !== null && auth.role === 'Admin' && urlSegment[0].path ==='admin') {
    //   return true
    // }
    // if (auth !== null && auth.role === 'Customer' && urlSegment[0].path !=='admin') {
    //   return true
    // }
    // this.router.navigate(['/']);
    return true;
  }
}
