import { NgModule } from '@angular/core';
import { MenuCategoriesComponent } from './menu-categories.component';
import { MenuCategoriesRoutingModule } from './menu-categories-routing.module';
import { MenuCategoriesListComponent } from './menu-categories-list/menu-categories-list.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { CommonModule } from '@angular/common';
import { RestaurantSelectionComponent } from '../restaurant-selection/restaurant-selection.component';
import { RestaurantSelectionModule } from '../restaurant-selection/restaurant-selection.module';



@NgModule({
  declarations: [
    MenuCategoriesComponent,
    MenuCategoriesListComponent,
  ],
  imports: [
    MenuCategoriesRoutingModule,
    SharedModule,
    CommonModule,
    RestaurantSelectionModule
  ],
})
export class MenuCategoriesModule { }
