import { DecimalPipe } from '@angular/common';
import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { CategoryService } from 'src/app/shared/service/category.service';

@Component({
  selector: 'app-restaurant-selection',
  templateUrl: './restaurant-selection.component.html',
  styleUrls: ['./restaurant-selection.component.scss'],
  providers: [DecimalPipe]
})
export class RestaurantSelectionComponent {
  restuarantId: string;
  public restuarants$: Observable<any[]>;
  
  constructor(public categoryService:CategoryService) {
    this.restuarants$ = categoryService.getAllRestuarants();
  }

  selectRestuarant(event: any) {
    this.categoryService.setRestuarantId(event);
  }

}
