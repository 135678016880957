<div id="batchDelete" class="category-table custom-datatable">
  <ng-container *ngIf="tableItem$ | async as searchRes">
    <div class="table-responsive">
      <!-- <form>
            <div class="mb-3 row">
                <label for="table-complete-search" class="col-xs-3 col-sm-auto col-form-label">Full text search:</label>
                <div class="col-xs-3 col-sm-auto">
                  <input id="table-complete-search" type="text" class="form-control" name="searchTerm"  [(ngModel)]="searchText" autocomplete="off">
                </div>
            </div>
          </form> -->
      <table class="table table-striped">
        <thead>
          <tr>
            <th scope="col" sortable="product_name" (sort)="onSort($event)">
              Name
            </th>
            <th scope="col" sortable="isRequired" (sort)="onSort($event)">
              Is Required
            </th>
            <th scope="col" sortable="optionsLimit" (sort)="onSort($event)">
              Options Limit
            </th>
            <th scope="col" sortable="basePrice" (sort)="onSort($event)">
              Price
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="
              let menuItemExtra of searchRes.data| filter : searchText
            "
          >
            <td>{{ menuItemExtra.name }}</td>
            <td>{{ menuItemExtra.isRequired }}</td>
            <td>{{ menuItemExtra.optionsLimit }}</td>
            <td>{{ menuItemExtra.basePrice }}</td>
            <td>
              <a
                href="javascript:void(0)"
                (click)="editExtraOption(menuItemExtra.id)"
                ><i class="fa fa-edit f-12"></i></a
              >&nbsp;
              <a
                href="javascript:void(0)"
                (click)="deleteExtraAndOptions(menuItemExtra.id)"
                ><i class="fa fa-trash-o"></i
              ></a>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="d-flex justify-content-center p-2">
        <mat-paginator
          #paginator
          class="demo-paginator"
          (page)="handlePageEvent($event)"
          [length]="searchRes.totalCount"
          [pageSize]="searchRes.pageSize"
          [disabled]="false"
          [showFirstLastButtons]="true"
          [pageSizeOptions]="false"
          [hidePageSize]="false"
          [pageIndex]="searchRes.currentPage - 1"
          aria-label="Select page"
        >
        </mat-paginator>
      </div>
    </div>
  </ng-container>
</div>

<app-menu-extra-modal
  #menuExtraModal
  [addOrEdit]="'Edit'"
  [menuItemExtraId]="selectedId"
></app-menu-extra-modal>
