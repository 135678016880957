import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { NavService } from '../../service/nav.service';
import { CategoryService } from '../../service/category.service';
import { DecimalPipe } from '@angular/common';
import { AccountService } from '../../service/account.service';
import { RestaurantService } from '../../service/restaurant.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  providers:[CategoryService,RestaurantService,DecimalPipe]
})
export class HeaderComponent implements OnInit {
  public right_sidebar: boolean = false;
  public open: boolean = false;
  public openNav: boolean = false;
  public isOpenMobile : boolean;
  public isOnline : boolean;

  @Output() rightSidebarEvent = new EventEmitter<boolean>();

  constructor(public navServices: NavService, public cate: CategoryService, private accountService: AccountService, private restaurantService:RestaurantService) { 
    cate.setRestuarantId("dfwsefwfwe");
    restaurantService.getOnlineStatus(accountService.getLoggedInUser().restaurantId).subscribe(res => this.isOnline = res);
  }

  collapseSidebar() {
    this.open = !this.open;
    this.navServices.collapseSidebar = !this.navServices.collapseSidebar
  }
  right_side_bar() {
    this.right_sidebar = !this.right_sidebar
    this.rightSidebarEvent.emit(this.right_sidebar)
  }

  openMobileNav() {
    this.openNav = !this.openNav;
  }

  onChange(event: any) {
    console.log("Toggle changed to: ", event.checked);
    this.restaurantService.setOnline(this.accountService.getLoggedInUser().restaurantId).subscribe();
  }


  ngOnInit() {  }

  logout(){
    this.accountService.logout();
  }
}
