import { DecimalPipe } from '@angular/common';
import { Component, EventEmitter, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject } from 'rxjs';
import { CategoryService } from 'src/app/shared/service/category.service';
import { TableService } from 'src/app/shared/service/table.service';
import { environment } from 'src/environments/environment';


import sanityClient from '@sanity/client'
import { DiscountManagementModalComponent } from 'src/app/shared/components/discount-management-modal/discount-management-modal.component';
import { DiscountService } from 'src/app/shared/service/discount.service';

@Component({
  selector: 'app-discount-management',
  templateUrl: './discount-management.component.html',
  styleUrls: ['./discount-management.component.scss'],
  providers: [DiscountService,TableService, DecimalPipe],
})
export class DiscountManagementComponent {

  @ViewChild('discountModal') private modalComponent!: DiscountManagementModalComponent;
  public closeResult: string;

  public selectedId : EventEmitter<string> = new EventEmitter<string>();
  public _selectedId = new BehaviorSubject<string>('');

  public selectedRestaurantId : EventEmitter<string> = new EventEmitter<string>();
  public _selectedRestaurantId = new BehaviorSubject<string>('');

  constructor(private modalService: NgbModal) {
  }
  open() {
    this.selectedId.emit("");
    this.modalComponent.open();
    this._selectedId.next('');
  }   

  ngOnInit() {
   
  }
}
