<div class="container-fluid">
    <div class="page-header">
        <div class="row">
            <div class="col-lg-6">
                <div class="page-header-left">
                    <h3>{{title}}
                    </h3>
                </div>
            </div>
            <div class="col-lg-6">

            </div>
        </div>
    </div>
</div>