import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { EventEmitter, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, of, ReplaySubject } from "rxjs";
import { map, tap } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { IUser } from "../models/userModel";
import { IUserInfo } from "../models/userInfo";
import { ILogin } from "../models/loginModel";
import { IRegister } from "../models/registerModel";

@Injectable({
  providedIn: "root",
})
export class AccountService {
  baseUrl = environment.apiUrl;

  private currentUserSource = new ReplaySubject<IUser>(1);
  currentUser$ = this.currentUserSource.asObservable();
  loggedInEvent = new EventEmitter();

  constructor(private http: HttpClient, private router: Router) {}

  get userEditInitialize() {
    return new Observable<any>((observer) => {
      observer.next({});
    });
  }

  UpdateUser(val: any) {
    return new Observable<any>((observer) => {
      observer.next(val);
    });
  }

  loadCurrentUser(token: string) {
    if (token === null) {
      this.currentUserSource.next(null);
      return of(null);
    }

    let headers = new HttpHeaders();
    headers = headers.set("Authorization", `Bearer ${token}`);

    return this.http
      .get(this.baseUrl + "AccountManagement/getcurrentuser", { headers })
      .pipe(
        map((user: IUser) => {
          if (user) {
            localStorage.setItem("token", user.token);
            localStorage.setItem("role", user.role);
            localStorage.setItem("email", user.email);
            localStorage.setItem("restaurantId", user.restaurantId);
            localStorage.setItem("restaurantName", user.restaurantName);
            this.currentUserSource.next(user);
          }
        })
      );
  }

  getCurrentUserInfo() {
    const token = localStorage.getItem("token");
    let headers = new HttpHeaders();
    headers = headers.set("Authorization", `Bearer ${token}`);

    return this.http.get<IUserInfo>(
      this.baseUrl + "AccountManagement/getcurrentuserinfo",
      { headers }
    );
  }

  getLoggedInUser() {
    const user: IUser = {
      displayName: "",
      email: localStorage.getItem("email"),
      restaurantId: localStorage.getItem("restaurantId"),
      restaurantName: localStorage.getItem("restaurantName"),
      role: localStorage.getItem("role"),
      token: localStorage.getItem("token"),
    };
    return user;
  }

  getCurrentRestaurantUserInfo(restaurantId: string) {
    let params = new HttpParams();
    params = params.append("restuarantId", restaurantId);

    const token = localStorage.getItem("token");
    let headers = new HttpHeaders();
    headers = headers.set("Authorization", `Bearer ${token}`);

    return this.http
      .get<IRegister>(
        this.baseUrl + "AccountManagement/getcurrentrestaurantuserinfo",
        {
          headers,
          params,
        }
      )
      .pipe(
        tap((user: any) => {
          if (user) {
            return user;
          }
        })
      );
  }

  login(loginModel: ILogin) {
    console.log(loginModel);
    return this.http
      .post<IUser>(this.baseUrl + "AccountManagement/login", loginModel)
      .pipe(
        map((user: IUser) => {
          if (user !== null) {
            localStorage.setItem("token", user.token);
            localStorage.setItem("role", user.role);
            localStorage.setItem("email", user.email);
            localStorage.setItem("restaurantId", user.restaurantId);
            localStorage.setItem("restaurantName", user.restaurantName);
            this.currentUserSource.next(user);
            this.loggedInEvent.emit();
            if (user.role === "SuperAdmin") {
              this.router.navigateByUrl("/restaurant-management/default");
            } else {
              this.router.navigateByUrl("/dashboard/default");
            }
          }
        })
      );
  }

  register(registerModel: IRegister) {
    return this.http
      .post<IUser>(this.baseUrl + "AccountManagement/register", registerModel)
      .pipe(
        map((user: IUser) => {
          if (user) {
            localStorage.setItem("token", user.token);
            localStorage.setItem("role", user.role);
            localStorage.setItem("email", user.email);
            localStorage.setItem("restaurantId", user.restaurantId);
            localStorage.setItem("restaurantName", user.restaurantName);
            this.currentUserSource.next(user);
            this.loggedInEvent.emit();
          }
        })
      );
  }

  logout() {
    this.loggedInEvent.emit();
    localStorage.removeItem("token");
    localStorage.removeItem("role");
    localStorage.removeItem("email");
    localStorage.removeItem("restaurantId");
    localStorage.removeItem("restaurantName");
    this.router.navigateByUrl("/auth/login");
    this.currentUserSource.next(null);
  }

  checkIfUserLoggedIn() {
    if (localStorage.getItem("token")) {
      return true;
    } else if (localStorage.getItem("token") === undefined) {
      return false;
    }
  }

  updateUserPassword(newPassword: string) {
    let headers = new HttpHeaders();
    headers = headers.set(
      "Authorization",
      `Bearer ${localStorage.getItem("token")}`
    );

    return this.http.post<any>(
      this.baseUrl + "AccountManagement/UpdatePasswordFromProfile",
      { email: "", token: "", password: newPassword },
      { headers }
    );
  }

  forgotPassword(email: string) {
    return this.http.post<any>(
      this.baseUrl + "AccountManagement/forgotpassword",
      { email: email }
    );
  }

  resetPassword(newPassword: string, email: string, token: string) {
    return this.http.post<any>(
      this.baseUrl + "AccountManagement/resetpassword",
      { email: email, token: token, password: newPassword }
    );
  }
}
