import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { FeatherIconsComponent } from "./components/feather-icons/feather-icons.component";
import { FooterComponent } from "./components/footer/footer.component";
import { HeaderComponent } from "./components/header/header.component";
import { SidebarComponent } from "./components/sidebar/sidebar.component";
import { ToggleFullscreenDirective } from "./directives/fullscreen.directive";
import { ContentLayoutComponent } from "./layout/content-layout/content-layout.component";
import { NavService } from "./service/nav.service";
import { WINDOW_PROVIDERS } from "./service/windows.service";
import { BreadcrumbComponent } from "./components/breadcrumb/breadcrumb.component";
import { RightSidebarComponent } from "./components/right-sidebar/right-sidebar.component";
import { TableService } from "./service/table.service";
import { NgbdSortableHeader } from "./directives/NgbdSortableHeader";
import { MenuItemsService } from "./service/menu.items.service";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { MenuExtraModalComponent } from "./components/menu-extra-modal/menu-extra-modal.component";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { MenuItemModalComponent } from "./components/menu-item-modal/menu-item-modal.component";
import { Ng2SearchPipeModule } from "ng2-search-filter";
import { MenuCategoriesModalComponent } from "./components/menu-categories-modal/menu-categories-modal.component";
import { MenuCategoryIndexModalComponent } from "./components/menu-category-index-modal/menu-category-index-modal.component";
import { MatSelectModule } from "@angular/material/select";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatCommonModule, MatNativeDateModule, MatOptionModule } from "@angular/material/core";
import { MatInputModule } from '@angular/material/input';
import { CategoryService } from "./service/category.service";
import { OrderService } from "./service/order.service";
import { SignalrService } from "./service/signalr.service";
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatPaginatorModule} from '@angular/material/paginator';
import { RestaurantModalComponent } from "./components/restaurant-modal/restaurant-modal.component";
import { AccountService } from "./service/account.service";
import { AdminUserCreateModalComponent } from './components/admin-user-create-modal/admin-user-create-modal.component';
import { DiscountManagementModalComponent } from "./components/discount-management-modal/discount-management-modal.component";
import { DiscountService } from "./service/discount.service";
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { ServiceProviderGroupModalComponent } from "./components/service-provider-group-modal/service-provider-group-modal.component";
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { PrintService } from "./service/print.service";

@NgModule({
  declarations: [
    ToggleFullscreenDirective,
    FeatherIconsComponent,
    FooterComponent,
    HeaderComponent,
    SidebarComponent,
    ContentLayoutComponent,
    BreadcrumbComponent,
    RightSidebarComponent,
    NgbdSortableHeader,
    MenuExtraModalComponent,
    MenuItemModalComponent,
    RestaurantModalComponent,
    MenuCategoriesModalComponent,
    MenuCategoryIndexModalComponent,
    AdminUserCreateModalComponent,
    DiscountManagementModalComponent,
    ServiceProviderGroupModalComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    DragDropModule,
    MatSlideToggleModule,
    FormsModule,
    NgbModule,
    Ng2SearchPipeModule,
    MatSelectModule,
    MatFormFieldModule,
    MatOptionModule,
    MatCommonModule,
    MatInputModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatAutocompleteModule
  ],
  providers: [NavService, TableService, MenuItemsService,CategoryService,OrderService,AccountService, SignalrService, DiscountService, PrintService, WINDOW_PROVIDERS],
  exports: [
    ReactiveFormsModule,
    FormsModule,
    CommonModule,
    FeatherIconsComponent,
    ToggleFullscreenDirective,
    NgbdSortableHeader,
    DragDropModule,
    MatSlideToggleModule,
    MenuExtraModalComponent,
    MenuItemModalComponent,
    RestaurantModalComponent,
    MenuCategoriesModalComponent,
    MenuCategoryIndexModalComponent,
    MatSelectModule,
    MatFormFieldModule,
    MatOptionModule,
    MatCommonModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatPaginatorModule,
    AdminUserCreateModalComponent,
    DiscountManagementModalComponent,
    MatProgressSpinnerModule,
    ServiceProviderGroupModalComponent,
    MatAutocompleteModule
  ],
})
export class SharedModule {}
