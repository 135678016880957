import { DecimalPipe } from '@angular/common';
import { Component, EventEmitter, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Observable } from 'rxjs';
import { MenuCategoriesModalComponent } from 'src/app/shared/components/menu-categories-modal/menu-categories-modal.component';
import { MenuCategoryIndexModalComponent } from 'src/app/shared/components/menu-category-index-modal/menu-category-index-modal.component';
import { CategoryService } from 'src/app/shared/service/category.service';
import { TableService } from 'src/app/shared/service/table.service';
import { Category } from 'src/app/shared/tables/category';
import { environment } from 'src/environments/environment';


import sanityClient from '@sanity/client'


const client = sanityClient({
  projectId: 'wl9gemnw', // find this at manage.sanity.io or in your sanity.json
  dataset: 'production', // or the name you chose in step 1
  useCdn: true, // `false` if you want to ensure fresh data
  token: environment.NEXT_PUBLIC_SANITY_TOKEN
})


@Component({
  selector: 'app-menu-categories',
  templateUrl: './menu-categories.component.html',
  styleUrls: ['./menu-categories.component.scss'],
  providers: [CategoryService,TableService, DecimalPipe],
})
export class MenuCategoriesComponent {

  @ViewChild('menuCategoriesModal') private modalComponent!: MenuCategoriesModalComponent;
  @ViewChild('menuCategoriesIndexModal') private modalIndexComponent!: MenuCategoryIndexModalComponent;
  public closeResult: string;

  public selectedId : EventEmitter<string> = new EventEmitter<string>();
  public _selectedId = new BehaviorSubject<string>('');

  public selectedRestaurantId : EventEmitter<string> = new EventEmitter<string>();
  public _selectedRestaurantId = new BehaviorSubject<string>('');

  constructor(private modalService: NgbModal) {
  }
  open() {
    this.selectedId.emit("");
    this.modalComponent.open();
    this._selectedId.next('');
  }  

  openIndexOrder() {
    this.selectedRestaurantId.emit("");
    this.modalIndexComponent.open();
    this._selectedRestaurantId.next('');
  }  

  ngOnInit() {
   
  }
}
