<ng-template #menuItemExtraModal let-modal>
    <div class="modal-header">
        <h5 class="modal-title f-w-600" id="exampleModalLabel">{{this.addOrEdit}} Menu Category</h5>
        <button type="button" class="close" aria-label="Close"
            (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <ng-container *ngIf="category$ | async as categoryRes">
    <div class="modal-body">
        <form class="needs-validation">
            <div class="form">
                <div class="form-group">
                    <label for="validationCustom01" class="mb-1" >Menu Category Name:</label>
                    <input class="form-control" id="categoryName" type="text" name="categoryName" [(ngModel)]="categoryRes.categoryName" (ngModelChange)="changeCategoryObject(categoryRes)">
                </div>
                <div class="form-group">
                    <label for="validationCustom01" class="mb-1" >Printer details</label>
                    <input class="form-control" id="printerDetails" type="text" name="printerDetails" [(ngModel)]="categoryRes.printerDetails" (ngModelChange)="changeCategoryObject(categoryRes)">
                </div>
                <div class="form-group">
                    <label for="extraRequired" class="mb-1">Active:</label>&nbsp;
                    <mat-slide-toggle
                      class="ml-2"
                      id="isActive"
                      name="isActive"
                      [(ngModel)]="categoryRes.isActive"
                      (ngModelChange)="changeCategoryObject(categoryRes)"
                    ></mat-slide-toggle>
                </div>
                <div class="form-group">
                    <label for="extraRequired" class="mb-1">Is Promo:</label>&nbsp;
                    <mat-slide-toggle
                      class="ml-2"
                      id="isTimePromo"
                      name="isTimePromo"
                      [(ngModel)]="categoryRes.promo.isTimePromo"
                      (ngModelChange)="changeCategoryObject(categoryRes)"
                    ></mat-slide-toggle>
                </div>
                <div class="form-group">
                    <label for="extraRequired" class="mb-1">Is Repeating:</label>&nbsp;
                    <mat-slide-toggle
                      class="ml-2"
                      id="isRepeating"
                      name="isRepeating"
                      [(ngModel)]="categoryRes.promo.isRepeating"
                      (ngModelChange)="changeCategoryObject(categoryRes)"
                    ></mat-slide-toggle>
                </div>
                <div class="row">
                    <div class="col-xl-6 xl-100">
                        <label for="birthdaytime">Promo Start Date:</label>
                        <input type="datetime-local" id="startDate" name="startDate" [(ngModel)]="categoryRes.promo.startDate" name="startDateInput" (ngModelChange)="changeCategoryObject(categoryRes)">                        
                    </div>
                    <div class="col-xl-6 xl-100">
                      <label for="birthdaytime">Promo End Date:</label>
                      <input type="datetime-local" id="endDate" name="endDate" [(ngModel)]="categoryRes.promo.endDate" name="endDateInput" (ngModelChange)="changeCategoryObject(categoryRes)">                                             
                    </div>
                </div>
                <div class="row">
                <mat-form-field>
                    <mat-label>Promo Days</mat-label>
                    <mat-select [formControl]="promoDaysOfWeekSelected" multiple>
                        <mat-option *ngFor="let day of daysOfTheWeek" [value]="day">{{day}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="modal.dismiss('Cross click')" (mouseup)="createCategory()">Save</button>
        <button class="btn btn-secondary" type="button" data-dismiss="modal"
            (click)="modal.dismiss('Cross click')">Close</button>
    </div>
</ng-container>
</ng-template>