<div class="page-wrapper">
    <div class="authentication-box">
        <div class="container">
            <div class="row">
                <div class="col-md-5 p-0 card-left">
                    <a href="https://qflow.co.za" class="card bg-primary qflowcard" 
                       style="background-image: url('/assets/images/dashboard/login-bg.png'); background-size: cover; background-repeat: no-repeat; background-position: center; width: 100%; height: 360px;">
                       
                    </a>
                </div>
                
                
                <div class="col-md-7 p-0 card-right">
                    <div class="card tab2-card">
                        <div class="card-body">
                            <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
                                <!-- Login Form -->
                                <li [ngbNavItem]="1">
                                    <a ngbNavLink>Login</a>
                                    <ng-template ngbNavContent>
                                        <div class="tab-pane fade active show" id="account" role="tabpanel"
                                            aria-labelledby="account-tab">
                                            <form [formGroup]="loginForm" class="form-horizontal auth-form"
                                                (ngSubmit)="login()">
                                                <div class="form-group">
                                                    <input formControlName="email" type="email" class="form-control"
                                                        placeholder="Email Address" required>
                                                </div>
                                                <div class="form-group">
                                                    <input formControlName="password" type="password"
                                                        class="form-control" placeholder="Password" required>
                                                </div>
                                                <div class="form-button">
                                                    <button class="btn btn-primary" type="submit">Login <mat-spinner *ngIf="loading === true" style="float: inline-end; margin-left: 10px;" [diameter]="25"></mat-spinner></button>                                                                                                  
                                                
                                                </div>  
                                            </form>
                                        </div>
                                    </ng-template>
                                </li>
                                <!-- Registration Form -->
                                <li [ngbNavItem]="2">
                                    <a ngbNavLink>Register</a>
                                    <ng-template ngbNavContent>
                                        <div class="tab-pane fade show active" id="register" role="tabpanel"
                                            aria-labelledby="register-tab">
                                            <div class="registration-info">
                                                <p style="color: #00a8ff ;">Looking to join QFlow? Send us an email and join today!</p>
                                                <form class="form-horizontal auth-form">
                                                    <input type="text" class="form-control" placeholder="Restaurant Name" #restaurantName required>
                                                    <br/>
                                                    <input type="text" class="form-control" placeholder="Contact Name" #contactName required>
                                                    <br/>
                                                    <input type="text" class="form-control" placeholder="Contact Number" #contactNumber required>
                                                    <br/>
                                                    <input type="email" class="form-control" placeholder="Your Email Address" #emailAddress required>
                                                    <br/>
                                                    <div class="form-button">
                                                        <button class="btn btn-primary" type="submit" (click)="sendEmail($event)">Submit</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </ng-template>
                                </li>
                            </ul>
                            <div [ngbNavOutlet]="nav" class="mt-2"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
