<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header">
                    <h5>Restaurants</h5>
                </div>
                <div class="card-body">
                    
                    <div class="btn-popup pull-right">
                        <button type="button" class="btn btn-primary" data-toggle="modal" data-original-title="test"
                            data-target="#exampleModal" (click)="open()">Add Restaurant</button>                      
                    </div>
                    <app-restaurant-management-list></app-restaurant-management-list>
                </div>
            </div>
        </div>
    </div>
</div>
<app-restaurant-modal #restaurantModal [addOrEdit]="'Add'" [restaurantId]="selectedId"></app-restaurant-modal>
<!-- Container-fluid Ends-->