<div id="batchDelete" class="category-table custom-datatable">
  <ng-container *ngIf="tableItem$ | async as searchRes">
    <div class="table-responsive">
      <!-- <form>
            <div class="mb-3 row">
                <label for="table-complete-search" class="col-xs-3 col-sm-auto col-form-label">Full text search:</label>
                <div class="col-xs-3 col-sm-auto">
                  <input id="table-complete-search" type="text" class="form-control" name="searchTerm"  [(ngModel)]="searchText" autocomplete="off">
                </div>
            </div>
          </form> -->
      <table class="table table-striped">
        <thead>
          <tr>
            <th scope="col">Name</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let discount of searchRes">
            <td>{{ discount.name }}</td>
            <td>
              <a href="javascript:void(0)" (click)="editCategory(discount.id)"
                ><i class="fa fa-edit f-12"></i></a
              >&nbsp;
              <a href="javascript:void(0)" (click)="deleteCategory(discount.id)"
                ><i class="fa fa-trash-o"></i
              ></a>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="d-flex justify-content-center p-2">
        <!-- <mat-paginator
          #paginator
          class="demo-paginator"
          (page)="handlePageEvent($event)"
          [length]="searchRes.totalCount"
          [pageSize]="searchRes.pageSize"
          [disabled]="false"
          [showFirstLastButtons]="true"
          [pageSizeOptions]="false"
          [hidePageSize]="false"
          [pageIndex]="searchRes.currentPage - 1"
          aria-label="Select page"
        >
        </mat-paginator> -->
      </div>
    </div>
  </ng-container>
</div>
<app-discount-management-modal
  #discountModal
  [addOrEdit]="'Edit'"
  [categoryId]="selectedId"
></app-discount-management-modal>
