import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuItemListComponent } from './menu-item-list/menu-item-list.component';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ProductsRoutingModule } from '../products/products-routing.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { GalleryModule } from '@ks89/angular-modal-gallery';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { SharedModule } from 'src/app/shared/shared.module';
import { MenuItemsComponent } from './menu-items.component';
import { MenuItemsRoutingModule } from './menu-items-routing.module';
import { RestaurantSelectionModule } from '../restaurant-selection/restaurant-selection.module';



@NgModule({
  declarations: [
    MenuItemsComponent,
    MenuItemListComponent
  ],
  imports: [
    Ng2SearchPipeModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ProductsRoutingModule,
    NgbModule,
    GalleryModule,
    CKEditorModule,
    NgxDropzoneModule,
    SharedModule,
    MenuItemsRoutingModule,
    RestaurantSelectionModule
  ],
})
export class MenuItemsModule { }
