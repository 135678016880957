import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChange,
  ViewChild,
} from "@angular/core";
import { MenuItemExtraWithOptionsRequestModel } from "../../tables/menu-item-extra-request";
import { MenuItemExtraOptionRequest } from "../../tables/menu-item-extra-option-request";
import {
  ModalDismissReasons,
  NgbModal,
  NgbModalRef,
} from "@ng-bootstrap/ng-bootstrap";
import { MenuItemsService } from "../../service/menu.items.service";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { BehaviorSubject, Observable, Subject, observable } from "rxjs";
import { v4 as uuid } from "uuid";
import { CategoryRequestModel } from "../../tables/catergory-request";
import { CategoryService } from "../../service/category.service";
import { AccountService } from "../../service/account.service";
import { FormControl } from "@angular/forms";

@Component({
  selector: "app-menu-categories-modal",
  templateUrl: "./menu-categories-modal.component.html",
  styleUrls: ["./menu-categories-modal.component.scss"],
})
export class MenuCategoriesModalComponent {
  @ViewChild("menuItemExtraOptionModal") menuItemExtraOptionModal: NgbModalRef;
  @ViewChild("menuItemExtraModal") menuItemExtraModal: NgbModalRef;

  @Input() addOrEdit: string;
  @Input() categoryId: Observable<string> = new Observable<string>();
  public promoDaysOfWeekSelected = new FormControl();

  public daysOfTheWeek = [
    'Sunday',
      'Monday',
      'Tuesday', 
      'Wednesday',
      'Thursday', 
      'Friday', 
      'Saturday' 
  ]

  public closeResult: string;

  category$: Observable<CategoryRequestModel> =
    new Observable<CategoryRequestModel>();

  menuItemExtra: any = {};

  restuarantId: string = "";
  dateNow = Date.now.toString();

  constructor(
    private modalService: NgbModal,
    public categoryService: CategoryService,
    private accountService:AccountService
  ) {
    const user = this.accountService.getLoggedInUser();
      this.restuarantId = user.restaurantId;
    this.category$ = categoryService.categoryEditInitialize;
  }
  ngOnInit(): void {
    // this.menuItemExtraId.subscribe(m => {if(m.length>0){
    //   this.menuItemExtra$ = this.menuItemService.getMenuItemExtraWithOptions(m);
    // }});
  }
  open() {
    this.modalService
      .open(this.menuItemExtraModal, { ariaLabelledBy: "modal-basic-title" })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }
  openMenuExtraOption(content) {
    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title" })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  changeCategoryObject(event: any) {
    this.category$ = this.categoryService.UpdateCatergoryObject(event);
  }

  createCategory() {
    this.category$.subscribe((cat) => {
      if (cat.id === undefined) {
        cat.promo.promoDays = this.promoDaysOfWeekSelected.value
        cat.id = uuid();        
        cat.restaurantId = this.restuarantId;
        this.categoryService.createCategory(cat).subscribe((res) => {});
      } else {
        if(!cat.promo.isTimePromo){
          cat.promo = undefined;
        }
        cat.promo.promoDays = this.promoDaysOfWeekSelected.value
        this.categoryService.editCategory(cat).subscribe((res) => {});
      }
    });
  }

  ngAfterViewInit() {
    this.categoryId.subscribe((m) => {
      if (m.length > 0) {
        
        this.category$ = this.categoryService.getCategoryById(m);
        this.category$.subscribe((c) => {          
        this.promoDaysOfWeekSelected.setValue(c.promo.promoDays);
          }) 
      } else {
        this.category$ = this.categoryService.categoryEditInitialize;
      }
    });
  }
}
