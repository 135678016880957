<div class="container-fluid">
  <div class="row">
    <div class="col-xl-12 xl-100">
      <div class="card height-equal">
        <div class="card-header">
          <h5>Get Your Order Reports</h5>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-xl-6 xl-100">
              <mat-form-field>
                <mat-label>Choose a date</mat-label>
                <input matInput [matDatepicker]="dateFrom" [(ngModel)]="orderFromDate" disabled  />
                <mat-hint>MM/DD/YYYY</mat-hint>
                <mat-datepicker-toggle
                  matIconSuffix
                  [for]="dateFrom"
                ></mat-datepicker-toggle>
                <mat-datepicker #dateFrom disabled="false"></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="col-xl-6 xl-100">
              <mat-form-field>
                <mat-label>Choose a date</mat-label>
                <input matInput [matDatepicker]="dateTo" [(ngModel)]="orderToDate" disabled />
                <mat-hint>MM/DD/YYYY</mat-hint>
                <mat-datepicker-toggle
                  matIconSuffix
                  [for]="dateTo"
                ></mat-datepicker-toggle>
                <mat-datepicker #dateTo disabled="false"></mat-datepicker>
              </mat-form-field>
            </div>
          </div>

          <a href="javascript:void(0)" (click)="getReport()" class="btn btn-primary"
            >Download report</a
          >
        </div>
      </div>
    </div>
    <ng-container *ngIf="orders$ | async as searchRes">
      <div class="col-xl-12 xl-100">
        <div class="card height-equal">
          <div class="card-header">
            <h5>All Orders</h5>
          </div>
            <div class="card-body">
              <div class="user-status table-responsive latest-order-table">
                <table class="table table-bordernone">
                  <thead>
                    <tr>
                      <th scope="col">Order ID</th>
                      <th scope="col">Order Total</th>
                      <th scope="col">Contact info</th>
                      <th scope="col">Order Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let order of searchRes.data">
                      <td>{{ order.orderId }}</td>
                      <td class="digits">R{{ order.amountInCents / 100 }}</td>
                      <td class="font-danger">TBD</td>
                      <td class="digits">{{ order.orderDate }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="d-flex justify-content-center p-2">
                <mat-paginator #paginator
                   class="demo-paginator"
                   (page)="handlePageEvent($event)"
                   [length]="searchRes.totalCount"
                   [pageSize]="searchRes.pageSize"
                   [disabled]="false"
                   [showFirstLastButtons]="true"
                   [pageSizeOptions]="false"
                   [hidePageSize]="false"
                   [pageIndex]="searchRes.currentPage - 1"
                   aria-label="Select page">
                </mat-paginator>
             </div> 
            </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
