import { Routes } from '@angular/router';
import { AuthGuard } from 'src/app/guards/auth.guards';

export const content: Routes = [
  {
    path: 'dashboard',
    loadChildren: () => import('../../components/dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate:[AuthGuard]
  },
  {
    path: 'order-management',
    loadChildren: () => import('../../components/order-history/order-history.module').then(m => m.OrderHistoryModule),
    canActivate:[AuthGuard]
  },
  {
    path: 'restaurant-management',
    loadChildren: () => import('../../components/restaurant-management/restaurant-management.module').then(m => m.RestaurantManagementModule),
    canActivate:[AuthGuard]
  },
  {
    path: 'menu-items',
    loadChildren: () => import('../../components/menu-items/menu-items.module').then(m => m.MenuItemsModule),
    canActivate:[AuthGuard]
  },
  {
    path: 'menu-item-extras',
    loadChildren: () => import('../../components/menu-item-extras/menu-item-extras.module').then(m => m.MenuItemExtrasModule),
    canActivate:[AuthGuard]
  },
  {
    path: 'menu-categories',
    loadChildren: () => import('../../components/menu-categories/menu-categories.module').then(m => m.MenuCategoriesModule),
    canActivate:[AuthGuard]
  },
  {
    path: 'discount-management',
    loadChildren: () => import('../../components/discount-management/discount-management.module').then(m => m.DiscountManagementModule),
    canActivate:[AuthGuard]
  },  
  {
    path: 'region-management',
    loadChildren: () => import('../../components/region-management/region-management.module').then(m => m.RegionManagementModule),
    canActivate:[AuthGuard]
  },
];