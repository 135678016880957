<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header">
                    <h5>Menu Items</h5>
                </div>
                <div class="card-body">
                    
                    <div class="btn-popup pull-right">
                        <button type="button" class="btn btn-primary" data-toggle="modal" data-original-title="test"
                            data-target="#exampleModal" (click)="open()">Add Menu Item</button>                      
                    </div>
                    <app-menu-item-list></app-menu-item-list>
                </div>
            </div>
        </div>
    </div>
</div>
<app-menu-item-modal #menuItemModal [addOrEdit]="'Add'" [menuItemId]="selectedId"></app-menu-item-modal>
<!-- Container-fluid Ends-->