import { Component, Input, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { CategoryService } from '../../service/category.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { BehaviorSubject, Observable } from 'rxjs';
import { ModalDismissReasons, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbdSortableHeader } from '../../directives/NgbdSortableHeader';
import { CategoryRequestModel } from '../../tables/catergory-request';
import { AccountService } from '../../service/account.service';

@Component({
  selector: 'app-menu-category-index-modal',
  templateUrl: './menu-category-index-modal.component.html',
  styleUrls: ['./menu-category-index-modal.component.scss']
})
export class MenuCategoryIndexModalComponent {
  menuCategories$: Observable<CategoryRequestModel[]>;
  menuItemImage:any;

  @Input() restaurantId :Observable<string> = new Observable<string>();


  @ViewChild('menuCategoryIndexModal') menuCategoriesIndexModal: NgbModalRef;
  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;
     

  public closeResult: string;
  searchText;
  total$: Observable<number>;


  public _menuCategories = new BehaviorSubject<CategoryRequestModel[]>([]);

  constructor(private modalService: NgbModal, public categoryService: CategoryService,private sanitizer: DomSanitizer, private accountService: AccountService){
    const user = this.accountService.getLoggedInUser();
    this.menuCategories$ = categoryService.getCategoriesForRestaurantNoPaging(user.restaurantId);
    this.menuCategories$.subscribe(v => {this._menuCategories.next(v)});      
  }
  ngOnInit(): void {
    // this.menuItemExtraId.subscribe(m => {if(m.length>0){
    //   this.menuItemExtra$ = this.menuItemService.getMenuItemExtraWithOptions(m);
    // }});
  }

  open() {
    this.modalService.open(this.menuCategoriesIndexModal, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this._menuCategories.value, event.previousIndex, event.currentIndex);
  }

  saveIndexes(){
    this._menuCategories.value.forEach(o => {o.index = this._menuCategories.value.indexOf(o)});
    this._menuCategories.value.forEach(cat => {
        this.categoryService.editCategory(cat).subscribe((res) => {});
      });
  }

  ngAfterViewInit() {

    this.restaurantId.subscribe(m => {
      const user = this.accountService.getLoggedInUser();
      this.menuCategories$ = this.categoryService.getCategoriesForRestaurantNoPaging(user.restaurantId);
      this.menuCategories$.subscribe(v => {this._menuCategories.next(v)});
    });         
  }

  clearState(){
    this._menuCategories.unsubscribe();
     this._menuCategories = new BehaviorSubject<any[]>([]);
  }
}
