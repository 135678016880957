<ng-template #serviceProviderGroupModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title f-w-600" id="exampleModalLabel">
      {{ this.addOrEdit }} Service Provider Group
    </h5>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click'); clearState()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <ng-container *ngIf="serviceProviderGroup$ | async as serviceProviderGroupRes">
    <div class="modal-body">
      <form class="needs-validation">
        <div class="form">
          <div class="form-group">
            <label for="validationCustom01" class="mb-1">Name :</label>
            <input
              class="form-control"
              id="validationCustom01"
              type="text"
              name="name"
              [(ngModel)]="serviceProviderGroupRes.name"
              (ngModelChange)="changeRestaurantProps(serviceProviderGroupRes)"
            />
          </div>
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-primary"
        (click)="modal.dismiss('Cross click')"
        (mouseup)="createOrEditRestaurant()"
      >
        Save
      </button>
      <button
        class="btn btn-secondary"
        type="button"
        data-dismiss="modal"
        (click)="modal.dismiss('Cross click'); clearState()"
      >
        Close
      </button>
    </div>
  </ng-container>
</ng-template>
