import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChange,
  ViewChild,
} from "@angular/core";
import { MenuItemExtraWithOptionsRequestModel } from "../../tables/menu-item-extra-request";
import { MenuItemExtraOptionRequest } from "../../tables/menu-item-extra-option-request";
import {
  ModalDismissReasons,
  NgbModal,
  NgbModalRef,
} from "@ng-bootstrap/ng-bootstrap";
import { MenuItemsService } from "../../service/menu.items.service";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { BehaviorSubject, Observable, Subject, observable } from "rxjs";
import { v4 as uuid } from "uuid";
import { MenuItemExtra } from "../../tables/menu-item-extra";
import { CategoryService } from "../../service/category.service";
import { AccountService } from "../../service/account.service";

@Component({
  selector: "app-menu-extra-modal",
  templateUrl: "./menu-extra-modal.component.html",
  styleUrls: ["./menu-extra-modal.component.scss"],
})
export class MenuExtraModalComponent implements AfterViewInit, OnInit {
  @ViewChild("menuItemExtraOptionModal") menuItemExtraOptionModal: NgbModalRef;
  @ViewChild("menuItemExtraModal") menuItemExtraModal: NgbModalRef;

  @Input() addOrEdit: string;
  @Input() menuItemExtraId: Observable<string> = new Observable<string>();
  restaurantId = "";

  public closeResult: string;

  menuItemExtra$: Observable<MenuItemExtraWithOptionsRequestModel> =
    new Observable<MenuItemExtraWithOptionsRequestModel>();

  menuItemExtra: any = {};

  public _extraOptions = new BehaviorSubject<any[]>([]);
  extraOption: MenuItemExtraOptionRequest = {
    name: "",
    description: "",
    id: "",
    menuItemExtraOptionEdited: false,
    price: 0,
    optionsIndex: 0,
  };

  constructor(
    private modalService: NgbModal,
    public menuItemService: MenuItemsService,
    public categoryService: CategoryService,
    private accountService: AccountService
  ) {
    const user = this.accountService.getLoggedInUser();
    this.restaurantId = user.restaurantId;
    this.menuItemExtra$ = menuItemService.menuItemExtraToEditInitialize;
    this.menuItemExtra$.subscribe((v) => {
      console.log(v);
    });
    this.menuItemExtra.menuItemExtraOptions =
      new Array<MenuItemExtraOptionRequest>();
  }
  ngOnInit(): void {
    // this.menuItemExtraId.subscribe(m => {if(m.length>0){
    //   this.menuItemExtra$ = this.menuItemService.getMenuItemExtraWithOptions(m);
    // }});
  }
  open() {
    this.modalService
      .open(this.menuItemExtraModal, { ariaLabelledBy: "modal-basic-title" })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }
  openMenuExtraOption(content) {
    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title" })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  drop(event: CdkDragDrop<string[]>) {
    this._extraOptions.value.forEach((v) => {
      return (v.menuItemExtraOptionEdited = true);
    });
    moveItemInArray(
      this._extraOptions.value,
      event.previousIndex,
      event.currentIndex
    );
  }

  addExtraOptionToArray(extraOption: MenuItemExtraOptionRequest) {
    if (extraOption.id.length === 0) {
      extraOption.id = uuid();
      this._extraOptions.value.push(extraOption);
    }

    this.extraOption = {
      name: "",
      description: "",
      id: "",
      menuItemExtraOptionEdited: false,
      price: 0,
      optionsIndex: 0,
    };
  }

  deleteExtraOptionToArray(extraOption: MenuItemExtraOptionRequest) {
    this._extraOptions.next(
      this._extraOptions.value.filter((v) => {
        return v.id !== extraOption.id;
      })
    );
  }

  createMenuItemExtraWithOptions() {
    this.menuItemExtra$.subscribe((v) => {
      let menuItemExtraToAddOrUpdate = this.addAndIndexOptions(v);
      menuItemExtraToAddOrUpdate.restuarantId = this.restaurantId;
      if (menuItemExtraToAddOrUpdate.menuItemExtraId === undefined) {
        menuItemExtraToAddOrUpdate.menuItemExtraId = uuid();
        this.menuItemService
          .createExtraAndOptions(menuItemExtraToAddOrUpdate)
          .subscribe((res) => {});
      } else {
        this.menuItemService
          .editExtraAndOptions(menuItemExtraToAddOrUpdate)
          .subscribe((res) => {});
      }

      console.log(menuItemExtraToAddOrUpdate);

    });
  }

  changeMenuItemExtra(event: any) {
    this.menuItemExtra$ = this.menuItemService.UpdateMenuItemExtra(event);
  }

  addAndIndexOptions(
    menuItemExtraWithOptions: MenuItemExtraWithOptionsRequestModel
  ) {
    //index options based on array position
    this._extraOptions.value.forEach((o) => {
      o.optionsIndex = this._extraOptions.value.indexOf(o);
    });
    menuItemExtraWithOptions.menuItemExtraOptions = this._extraOptions.value;

    return menuItemExtraWithOptions;
  }

  onChange(event: any) {
    console.log("Toggle changed to: ", event.checked);

  }

  editExtraOption(menuItemExtraOptionToEdit: any) {
    this.extraOption = menuItemExtraOptionToEdit;
    this.extraOption.menuItemExtraOptionEdited = true;
    this.openMenuExtraOption(this.menuItemExtraOptionModal);
  }

  ngAfterViewInit() {
    this.menuItemExtraId.subscribe((m) => {
      if (m.length > 0) {
        this.menuItemExtra$ =
          this.menuItemService.getMenuItemExtraWithOptions(m);
        this.menuItemExtra$.subscribe((v) => {
          this._extraOptions.next(v.menuItemExtraOptions);
        });
      } else {
        this.menuItemExtra$ = this.menuItemService.menuItemToEditInitialize;
      }
    });
  }
}
