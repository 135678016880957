import { Injectable } from '@angular/core';

import { BehaviorSubject, observable, Observable, of, Subject } from 'rxjs';

import { DecimalPipe } from '@angular/common';
import { debounceTime, delay, map, switchMap, tap } from 'rxjs/operators';
import { SortColumn, SortDirection } from '../directives/NgbdSortableHeader';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { MenuItemExtraWithOptionsRequestModel } from '../tables/menu-item-extra-request';
import { MenuItemRequestModel } from '../tables/menu-item-request';



interface SearchResult {
  tableItem: any[];
  total: number;
}

interface State {
  page: number;
  pageSize: number;
  searchTerm: string;
  sortColumn: SortColumn;
  sortDirection: SortDirection;
}

const compare = (v1: string | number, v2: string | number) => v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

function sort(tableItem: any[], column: SortColumn, direction: string): any[] {
    if (direction === '' || column === '') {
        return tableItem;
    } else {
        return [...tableItem].sort((a, b) => {
            const res = compare(a[column], b[column]);
            return direction === 'asc' ? res : -res;
        });
    }
}

@Injectable({
  providedIn: 'root'
})
export class MenuItemsService {

  baseUrl = environment.apiUrl;

  private _loading$ = new BehaviorSubject<boolean>(true);
    private _search$ = new Subject<void>();
    private _menuItemExtra$ = new BehaviorSubject<any>({});
    private _menuItem$ = new BehaviorSubject<any>({});
    private _menuItemExtraToEdit$ = new BehaviorSubject<any>({});
    private _menuItemToEdit$ = new BehaviorSubject<any>({});
    private _total$ = new BehaviorSubject<number>(0);
    private menuItemExtras : any;
    private menuItems : any;
    private menuItemExtraToEdit : any;

    userData;

    private _state: State = {
        page: 1,
        pageSize: 10,
        searchTerm: '',
        sortColumn: '',
        sortDirection: ''
    };

    constructor(private pipe: DecimalPipe,private http: HttpClient) {
        // this._search$.pipe(
        //     tap(() => this._loading$.next(true)),
        //     debounceTime(200),
        //     switchMap(() => this._search()),
        //     delay(200),
        //     tap(() => this._loading$.next(false))
        // ).subscribe(result => {
        //     this._tableItem$.next(result.tableItem);
        //     this._total$.next(result.total);
        // });

        this._search$.next();
    }

    get total$() { return this._total$.asObservable(); }
    get loading$() { return this._loading$.asObservable(); }
    get page() { return this._state.page; }
    get pageSize() { return this._state.pageSize; }
    get searchTerm() { return this._state.searchTerm; }
    get menuItemExtraToEditInitialize() {return new Observable<any>(observer => {
      observer.next({})
    });}

    get menuItemToEditInitialize() {return new Observable<any>(observer => {
      observer.next({})
    });}   

    set page(page: number) {
        this._set({ page });
    }
    set pageSize(pageSize: number) { this._set({ pageSize }); }
    set searchTerm(searchTerm: string) { this._set({ searchTerm }); }
    set sortColumn(sortColumn: SortColumn) { this._set({ sortColumn }); }
    set sortDirection(sortDirection: SortDirection) { this._set({ sortDirection }); }

    setUserData(val: object) {
        console.log("val", val);

        this.userData = val;
    }

    GetMenuItems(){
      return this._menuItem$.asObservable()
    }

    UpdateMenuItemExtra(val : any)
    {
      return new Observable<any>(observer => {
         observer.next(val)
      });
    }

    UpdateMenuItem(val : any)
    {
      return new Observable<any>(observer => {
         observer.next(val)
      });
    }

    private _set(patch: Partial<State>) {
        Object.assign(this._state, patch);
        this._search$.next();
    }

    // private _search(): Observable<SearchResult> {
    //     const { sortColumn, sortDirection, pageSize, page } = this._state;

    //     // 1. sort
    //     let tableItem = sort(this.userData, sortColumn, sortDirection);
    //     console.log("tableItem", tableItem);

    //     // 2. filter
    //     const total = tableItem.length;

    //     tableItem = tableItem
    //         .map((item, i) => ({ id: i + 1, ...item }))
    //         .slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize);
    //     console.log("total ", tableItem);
    //     return of({ tableItem, total });
    // }

  public getMenuItemExtras(){
    return this.http.get<any[]>(this.baseUrl + 'MenuManagement/getmenuitemextras').pipe(
      tap((menuItemExtras: any[]) => {
        if (menuItemExtras) {    
          this.menuItemExtras = menuItemExtras;      
           return this._menuItemExtra$.next(menuItemExtras);
        }
      })
    );
  }

  
  public getMenuItemExtrasByRestaurantId(restaurantId: string,pageNumber: number, pageSize: number){
    let params = new HttpParams();
    params = params.append('restuarantId', restaurantId);

    if (pageNumber !== null && pageSize !== null) {
      params = params.append('pageNumber', pageNumber.toString());
      params = params.append('pageSize', pageSize.toString());
    }
    return this.http.get<any[]>(this.baseUrl + 'MenuManagement/getmenuitemextrasbyrestaurantid',{params}).pipe(
      tap((menuItemExtras: any[]) => {
        if (menuItemExtras) {    
          this.menuItemExtras = menuItemExtras;      
           return this._menuItemExtra$.next(menuItemExtras);
        }
      })
    );
  }

  public getAllMenuItemsExtrasWithOptions(){
    return this.http.get<any[]>(this.baseUrl + 'MenuManagement/getallmenuitemextrawithoptions').pipe(
      tap((menuItemExtras: any[]) => {
        if (menuItemExtras) {    
          this.menuItemExtras = menuItemExtras;      
           return this._menuItemExtra$.next(menuItemExtras);
        }
      })
    );
  }

  
  public getAllMenuItemsExtrasWithOptionsByRestaurantId(restaurantId: string){
    let params = new HttpParams();
    params = params.append('restaurantId', restaurantId);
    return this.http.get<any[]>(this.baseUrl + 'MenuManagement/getallmenuitemextrawithoptionsbyrestaurantid',{params}).pipe(
      tap((menuItemExtras: any[]) => {
        if (menuItemExtras) {    
          this.menuItemExtras = menuItemExtras;      
           return this._menuItemExtra$.next(menuItemExtras);
        }
      })
    );
  }

  public getMenuItemExtraWithOptions(menuItemExtraId:string){
    console.log(menuItemExtraId);
    let params = new HttpParams();
    params = params.append('menuItemExtraId', menuItemExtraId);
    return this.http.get<any>(this.baseUrl + 'MenuManagement/getmenuitemextrawithoptions',{params}).pipe(
      tap((menuItemExtras: any) => {
        
        console.log('API get Menu Items ' + menuItemExtras);
        if (menuItemExtras) {    
          this.menuItemExtraToEdit = menuItemExtras;      
           return this._menuItemExtraToEdit$.next(menuItemExtras);
        }
      })
    );
  }
  
  public getMenuItemWithExtras(menuItemId:string){
    console.log(menuItemId);
    let params = new HttpParams();
    params = params.append('menuItemId', menuItemId);
    return this.http.get<any>(this.baseUrl + 'MenuManagement/getmenuitemwithextras',{params}).pipe(
      tap((menuItem: any) => {
        if (menuItem) {         
           return this._menuItemToEdit$.next(menuItem);
        }
      })
    );
  }

  public getMenuItemWithExtrasAndOptions(menuItemId:string){
    console.log(menuItemId);
    let params = new HttpParams();
    params = params.append('menuItemId', menuItemId);
    return this.http.get<any>(this.baseUrl + 'MenuManagement/getmenuitemwithextrasandoptions',{params}).pipe(
      tap((menuItem: any) => {
        if (menuItem) {         
           return this._menuItemToEdit$.next(menuItem);
        }
      })
    );
  }

  public getMenuItems(){
    return this.http.get<any[]>(this.baseUrl + 'MenuManagement/getmenuitems').pipe(
      tap((menuItemExtras: any[]) => {
        console.log('API get Menu Items ' + menuItemExtras);
        if (menuItemExtras) {    
          this.menuItems = menuItemExtras;      
           return this._menuItem$.next(menuItemExtras);
        }
      })
    );
  }

  public getMenuItemsByRestaurantId(restaurantId: string,pageNumber: number, pageSize: number){
    let params = new HttpParams();
    params = params.append('restuarantId', restaurantId);

    if (pageNumber !== null && pageSize !== null) {
      params = params.append('pageNumber', pageNumber.toString());
      params = params.append('pageSize', pageSize.toString());
    }

    return this.http.get<any>(this.baseUrl + 'MenuManagement/getmenuitemsbyrestaurantid',{params}).pipe(
      tap((menuItems: any) => {
        console.log('API get Menu Items ');
        console.log(menuItems.data);
        if (menuItems) {    
          this.menuItems = menuItems;      
           return this._menuItem$.next(menuItems);
        }
      })
    );
  }

  public createExtraAndOptions(menuExtraToCreate: MenuItemExtraWithOptionsRequestModel){
    return this.http.post<any>(this.baseUrl + 'MenuManagement/createextraandoptions',menuExtraToCreate).pipe(
      tap((menuItemExtraAdded: any[]) => {
        if (menuItemExtraAdded) { 
          this.menuItemExtras.data.push(menuItemExtraAdded)         
           return this._menuItemExtra$.next(this.menuItemExtras);
        }
      })
    );
  }

  public createMenuItemWithExtraAndOptions(menuItemToCreate: MenuItemRequestModel){
    return this.http.post<any>(this.baseUrl + 'MenuManagement/createandassignmenuitemextrasandoptions',menuItemToCreate).pipe(
      tap((menuItemExtraAdded: any[]) => {
        if (menuItemExtraAdded) {
          this.menuItems.data.push(menuItemToCreate)         
           return this._menuItem$.next(this.menuItems);
        }
      })
    );
  }

  public editExtraAndOptions(menuExtraToCreate: MenuItemExtraWithOptionsRequestModel){
    return this.http.post<any>(this.baseUrl + 'MenuManagement/editmenuitemextraandoptions',menuExtraToCreate).pipe(
      tap((menuItemExtraAdded: any) => {
        if (menuItemExtraAdded) { 
          let indexToUpdate = this.menuItemExtras.data.findIndex(item => item.id === menuExtraToCreate.menuItemExtraId);   
          this.menuItemExtras.data[indexToUpdate] = menuExtraToCreate;  
          this.menuItemExtras.data = Object.assign([], this.menuItemExtras.data);      
          return this._menuItemExtra$.next(this.menuItemExtras);
        }
      })
    );
  }
  
  public editAndAssignMenuItemExtrasAndOptions(menuItemToEdit: MenuItemRequestModel){
    return this.http.post<any>(this.baseUrl + 'MenuManagement/editandassignmenuitemextrasandoptions',menuItemToEdit).pipe(
      tap((menuItemExtraAdded: any[]) => {
        if (menuItemExtraAdded) { 
          let indexToUpdate = this.menuItems.data.findIndex(item => item.id === menuItemToEdit.menuItemId);   
          this.menuItems.data[indexToUpdate] = menuItemToEdit;  
          this.menuItems.data = Object.assign([], this.menuItems.data);        
          return this._menuItem$.next(this.menuItems);
        }
      })
    );
  }

  public deleteMenuItem(menuItemId:string){
    return this.http.post<boolean>(this.baseUrl + 'MenuManagement/deletemenuitem',{"menuItemId":menuItemId}).pipe(
      tap((menuItemDeleted: boolean) => {
        if(menuItemDeleted)
        {
          this.menuItems.data = this.menuItems.data.filter(m => {return m.id !== menuItemId});      
           return this._menuItem$.next(this.menuItems);
        }
      })
    );
  }

  public deleteExtraAndOptions(menuItemExtraId:string){
    return this.http.post<boolean>(this.baseUrl + 'MenuManagement/deletemenuitemextra',{"menuItemExtraId":menuItemExtraId}).pipe(
      tap((menuItemExtraAdded: boolean) => {
        this.menuItemExtras.data = this.menuItemExtras.data.filter(m => {return m.id !== menuItemExtraId});      
        return this._menuItemExtra$.next(this.menuItemExtras);
      })
    );
  }
}
