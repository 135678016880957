import { DecimalPipe } from '@angular/common';
import { Component, EventEmitter, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Observable } from 'rxjs';
import { MenuItemModalComponent } from 'src/app/shared/components/menu-item-modal/menu-item-modal.component';
import { NgbdSortableHeader } from 'src/app/shared/directives/NgbdSortableHeader';
import { CategoryService } from 'src/app/shared/service/category.service';
import { MenuItemsService } from 'src/app/shared/service/menu.items.service';
import { RestaurantService } from 'src/app/shared/service/restaurant.service';
import { Category } from 'src/app/shared/tables/category';

@Component({
  selector: 'app-region-management',
  templateUrl: './region-management.component.html',
  styleUrls: ['./region-management.component.scss'],  
  providers:[DecimalPipe,MenuItemsService,CategoryService,RestaurantService]
})
export class RegionManagementComponent {
  @ViewChild('menuItemExtrasModal') menuItemExtrasModal: NgbModalRef;
  @ViewChild('serviceProviderGroupModal') menuItemModal: NgbModalRef;
  @ViewChild('serviceProviderGroupModal') private modalComponent!: MenuItemModalComponent;
  public closeResult: string;

  searchText;
  tableItem$: Observable<Category[]>;
  total$: Observable<number>;

  public selectedId : EventEmitter<string> = new EventEmitter<string>();
  public _selectedId = new BehaviorSubject<string>('');

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  constructor(private modalService: NgbModal) {
  }
  open() {
    this.selectedId.emit("");
    this.modalComponent.open();
    this._selectedId.next('');
  }  

  ngOnInit() {
  }
}
