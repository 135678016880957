import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { CommonModule } from '@angular/common';
import { RestaurantSelectionComponent } from '../restaurant-selection/restaurant-selection.component';
import { RestaurantSelectionModule } from '../restaurant-selection/restaurant-selection.module';
import { DiscountManagementRoutingModule } from './discount-management-routing.module';
import { DiscountManagementListComponent } from './discount-management-list/discount-management-list.component';
import { DiscountManagementComponent } from './discount-management.component';



@NgModule({
  declarations: [
    DiscountManagementComponent,
    DiscountManagementListComponent,
  ],
  imports: [
    DiscountManagementRoutingModule,
    SharedModule,
    CommonModule,
    RestaurantSelectionModule
  ],
})
export class DiscountManagementModule { }
