// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl: 'https://apiqflow.azurewebsites.net/',
  NEXT_PUBLIC_SANITY_TOKEN: "skSw8OfVDIEKhZqptlzgD7XoA90aO2DSEFCY5fIiZS2lGef6u7hhyYLfbPVWyemNwO6pDfkElxTk5aQazf58i2HjQOrhOwigl6zDlzxPQH08AmFFGucJudFq3KuDu44TlnWDu2XsQKwolikWVr5PQfw7gMojbv2u0HyfPGAxPHUeEd0i9Nmn"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.


