<ng-template #discountModal let-modal>
    <div class="modal-header">
        <h5 class="modal-title f-w-600" id="exampleModalLabel">{{this.addOrEdit}} Discount</h5>
        <button type="button" class="close" aria-label="Close"
            (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <ng-container *ngIf="discount$ | async as discountRes">
    <div class="modal-body">
        <form class="needs-validation">
            <div class="form">
                <div class="form-group">
                    <label for="validationCustom01" class="mb-1" >Discount Name:</label>
                    <input class="form-control" id="name" type="text" name="name" [(ngModel)]="discountRes.name" (ngModelChange)="changeCategoryObject(discountRes)">
                </div>
                <div class="form-group">
                    <label for="validationCustom01" class="mb-1" >Amount</label>
                    <input class="form-control" id="value" type="number" name="value" [(ngModel)]="discountRes.content.value" (ngModelChange)="changeCategoryObject(discountRes)">
                </div>
                <div class="form-group">
                    <mat-form-field>
                        <mat-label>Discount Type</mat-label>
                        <mat-select [formControl]="typeOfDiscount" >
                            <mat-option *ngFor="let discountType of discountTypes" [value]="discountType">{{discountType}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="form-group">
                    <label for="extraRequired" class="mb-1">Active:</label>&nbsp;
                    <mat-slide-toggle
                      class="ml-2"
                      id="isActive"
                      name="isActive"
                      [(ngModel)]="discountRes.content.isActive"
                      (ngModelChange)="changeCategoryObject(discountRes)"
                    ></mat-slide-toggle>
                </div>
                <div class="row">
                    <div class="col-xl-6 xl-100">
                        <label for="birthdaytime">Start Date:</label>
                        <input type="datetime-local" id="startDate" name="startDate" [(ngModel)]="discountRes.content.startDate" name="startDate" (ngModelChange)="changeCategoryObject(discountRes)">                        
                    </div>
                    <div class="col-xl-6 xl-100">
                      <label for="birthdaytime">End Date:</label>
                      <input type="datetime-local" id="endDate" name="endDate" [(ngModel)]="discountRes.content.endDate" name="endDate" (ngModelChange)="changeCategoryObject(discountRes)">                                             
                    </div>
                </div>                 
                <ng-container *ngIf="menuItems$ | async as menuItems ">               
                <mat-form-field>
                    <mat-label>Menu Items To Exlude</mat-label>
                    <mat-select [formControl]="menuItemsSelected" multiple>
                        <mat-option *ngFor="let menuItem of menuItems" [value]="menuItem.id">{{menuItem.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </ng-container>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="modal.dismiss('Cross click')" (mouseup)="createCategory()">Save</button>
        <button class="btn btn-secondary" type="button" data-dismiss="modal"
            (click)="modal.dismiss('Cross click')">Close</button>
    </div>
</ng-container>
</ng-template>