<ng-template #adminUserCreate let-modal>
    <div class="modal-header">
        <h5 class="modal-title f-w-600" id="exampleModalLabel"> Admin User</h5>
        <button type="button" class="close" aria-label="Close"
            (click)="modal.dismiss('Cross click');clearState()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <ng-container *ngIf="user$ | async as userRes">
    <div class="modal-body">
        <form class="needs-validation">
            <div class="form">
                <div class="form-group">
                    <label for="validationCustom01" class="mb-1" >User Name :</label>
                    <input class="form-control" id="validationCustom01" type="text" name="userName" [(ngModel)]="userRes.userName" (ngModelChange)="changeMenuItem(userRes)">
                </div>
                <div class="form-group">
                    <label for="validationCustom01" class="mb-1" >Email</label>
                    <input class="form-control" id="validationCustom01" type="text" name="email" [(ngModel)]="userRes.email" (ngModelChange)="changeMenuItem(userRes)">
                </div>
                <div class="form-group">
                    <label for="validationCustom01" class="mb-1" >Password</label>
                    <input class="form-control" id="validationCustom01" type="text" name="password" [(ngModel)]="userRes.password" (ngModelChange)="changeMenuItem(userRes)">
                </div>                 
            </div>
        </form>
    </div>   
    <ng-container *ngIf="paymentDetails$ | async as paymentDetailsRes">
    <div class="modal-body">
        <form class="needs-validation">
            <div class="form">
                <div class="form-group">
                    <label for="validationCustom01" class="mb-1" >Public Key:</label>
                    <input class="form-control" id="publicKey" type="text" name="publicKey" [(ngModel)]="paymentDetailsRes.publicKey" (ngModelChange)="changePaymentDetails(paymentDetailsRes)">
                </div>
                <div class="form-group">
                    <label for="validationCustom01" class="mb-1" >Secret Key</label>
                    <input class="form-control" id="secretKey" type="text" name="secretKey" [(ngModel)]="paymentDetailsRes.secretKey" (ngModelChange)="changeMenuItem(paymentDetailsRes)">
                </div>
                <div class="form-group">
                    <label for="validationCustom01" class="mb-1" >Provider Type</label>
                    <input class="form-control" id="providerType" type="text" name="providerType" [(ngModel)]="paymentDetailsRes.providerType" (ngModelChange)="changeMenuItem(paymentDetailsRes)">
                </div>                 
            </div>
        </form>
    </div>
    <div>
        <button type="button" class="btn btn-primary" style="margin-left: 50px;"  (mouseup)="createPaymentDetails()">Save Payment Details</button>
    </div>
</ng-container>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="modal.dismiss('Cross click');" (mouseup)="createUser()">Save</button>
        <button class="btn btn-secondary" type="button" data-dismiss="modal"
            (click)="modal.dismiss('Cross click');clearState()">Close</button>
    </div>
</ng-container>
</ng-template>