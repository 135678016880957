import {
  Component,
  EventEmitter,
  QueryList,
  ViewChild,
  ViewChildren,
} from "@angular/core";
import { PageEvent } from "@angular/material/paginator";
import {
  ModalDismissReasons,
  NgbModal,
  NgbModalRef,
} from "@ng-bootstrap/ng-bootstrap";
import { Observable } from "rxjs";
import { MenuExtraModalComponent } from "src/app/shared/components/menu-extra-modal/menu-extra-modal.component";
import { NgbdSortableHeader } from "src/app/shared/directives/NgbdSortableHeader";
import { AccountService } from "src/app/shared/service/account.service";
import { CategoryService } from "src/app/shared/service/category.service";
import { MenuItemsService } from "src/app/shared/service/menu.items.service";
import { TableService } from "src/app/shared/service/table.service";
import { CATEGORY, Category } from "src/app/shared/tables/category";
import { MenuItemExtra } from "src/app/shared/tables/menu-item-extra";
import { MenuItemExtraOptionRequest } from "src/app/shared/tables/menu-item-extra-option-request";
import { MenuItemExtraWithOptionsRequestModel } from "src/app/shared/tables/menu-item-extra-request";
@Component({
  selector: "app-menu-item-extra-list",
  templateUrl: "./menu-item-extra-list.component.html",
  styleUrls: ["./menu-item-extra-list.component.scss"],
})
export class MenuItemExtraListComponent {
  selectedMenuItemExtraId: string;
  public selectedId: EventEmitter<string> = new EventEmitter<string>();
  searchText;
  tableItem$: Observable<MenuItemExtra[]>;
  total$: Observable<number>;
  @ViewChild("menuExtraModal") private modalComponent!: MenuExtraModalComponent;
  restuarantId: string = "";

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  constructor(
    public service: TableService,
    public menuItemService: MenuItemsService,
    private modalService: NgbModal,
    public categoryService: CategoryService,
    private accountService: AccountService
  ) {
    const user = this.accountService.getLoggedInUser();
    this.restuarantId = user.restaurantId;
    this.tableItem$ = menuItemService.getMenuItemExtrasByRestaurantId(
      user.restaurantId,
      1,
      10
    );
    this.service.setUserData(CATEGORY);
  }

  onSort({ column, direction }) {
    // resetting other headers
    console.log("dsafsa");

    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = "";
      }
    });

    this.service.sortColumn = column;
    this.service.sortDirection = direction;
  }

  editExtraOption(selectedId: string) {
    this.selectedMenuItemExtraId = selectedId;
    this.selectedId.emit(selectedId);
    this.modalComponent.open();
  }

  deleteExtraAndOptions(menuItemExtraId: string) {
    this.menuItemService.deleteExtraAndOptions(menuItemExtraId).subscribe();
  }

  handlePageEvent(e: PageEvent) {
    console.log(e);
    this.tableItem$ = this.menuItemService.getMenuItemExtrasByRestaurantId(
      this.restuarantId,
      e.pageIndex + 1,
      e.pageSize
    );
  }
}
