import { Component, OnInit } from "@angular/core";
import * as chartData from "../../shared/data/chart";
import { doughnutData, pieData } from "../../shared/data/chart";
import { environment } from "src/environments/environment";
import sanityClient from "@sanity/client";
import { OrderService } from "src/app/shared/service/order.service";
import { DecimalPipe } from "@angular/common";
import { Observable, async } from "rxjs";
import { SignalrService } from "src/app/shared/service/signalr.service";
import { HttpClient } from "@angular/common/http";
import { CategoryService } from "src/app/shared/service/category.service";
import { AccountService } from "src/app/shared/service/account.service";
import { PrintService } from "src/app/shared/service/print.service";

const client = sanityClient({
  projectId: "wl9gemnw", // find this at manage.sanity.io or in your sanity.json
  dataset: "production", // or the name you chose in step 1
  useCdn: true, // `false` if you want to ensure fresh data
  token: environment.NEXT_PUBLIC_SANITY_TOKEN,
});

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
  providers: [OrderService, DecimalPipe, CategoryService, PrintService],
})
export class DashboardComponent implements OnInit {
  orders$: Observable<any[]>;
  restuarantId = "";

  constructor(
    public orderService: OrderService,
    public signalRService: SignalrService,
    private http: HttpClient,
    public categoryService: CategoryService,
    private accountService: AccountService,
    private printService: PrintService
  ) {
    Object.assign(this, { doughnutData, pieData });
  }

  async ngOnInit() {
    const user = this.accountService.getLoggedInUser();
    await this.signalRService.startConnection(user.restaurantId);
    await this.signalRService
      .addTransferOrderDataListener(user.restaurantId)
      .finally();
    this.startHttpRequest(user.restaurantId);
  }

  private startHttpRequest = (restaurantId: string) => {
    this.http
      .get(environment.apiUrl + "OrderManagement?restuarantId=" + restaurantId)
      .subscribe((res) => {
        console.log(res);
      });

    console.log(this.signalRService.data);
  };

  public updateOrderStatus(orderStatus: string, order: any) {
    if(orderStatus === 'accepted'){
      this.printService.printReceipt(order);
    }
    order.orderStatus = orderStatus;
    var now = new Date();

    // Convert to ISO string and remove 'Z' (UTC timezone indicator)
    var formattedDate = now.toISOString().replace("Z", "");

    var year = now.getFullYear();
    var month = (now.getMonth() + 1).toString().padStart(2, "0");
    var day = now.getDate().toString().padStart(2, "0");
    var hours = now.getHours().toString().padStart(2, "0");
    var minutes = now.getMinutes().toString().padStart(2, "0");
    var seconds = now.getSeconds().toString().padStart(2, "0");
    var milliseconds = now.getMilliseconds().toString().padStart(3, "0");

    var localDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}`;

    order.orderDateUpdated = formattedDate;
    console.log(localDate);
    //this.orderService.updateOrder(order).subscribe();
  }

  public printReceipt(){
    this.printService.printReceipt({});
  }
}
