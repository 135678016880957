<div class="page-main-header" [class.open]="open">
    <div class="main-header-right row">
        <div class="nav-right col">
            <div class="form-group">
                <label for="isonline" class="mb-1"><b>{{isOnline? 'Online':'Offline'}}</b></label>&nbsp;
                <mat-slide-toggle
                  class="ml-2"
                  id="isonline"
                  name="isRequired"
                  [(ngModel)]="isOnline"
                  (change)="onChange($event)"
                ></mat-slide-toggle>
              </div>
        </div>
        <div class="nav-right col">
            <button type="button" class="btn btn-primary" (click)="logout()">Logout</button>
        </div>
    </div>
</div>