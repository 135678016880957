import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { AgGridModule } from '@ag-grid-community/angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { DashboardModule } from './components/dashboard/dashboard.module';
import { SharedModule } from './shared/shared.module';
import { ProductsModule } from './components/products/products.module';
import { SalesModule } from './components/sales/sales.module';
import { CouponsModule } from './components/coupons/coupons.module';
import { PagesModule } from './components/pages/pages.module';
import { MediaModule } from './components/media/media.module';
import { MenusModule } from './components/menus/menus.module';
import { VendorsModule } from './components/vendors/vendors.module';
import { UsersModule } from './components/users/users.module';
import { LocalizationModule } from './components/localization/localization.module';
import { InvoiceModule } from './components/invoice/invoice.module';
import { SettingModule } from './components/setting/setting.module';;
import { ReportsModule } from './components/reports/reports.module';
import { AuthModule } from './components/auth/auth.module';
import { MenuItemsModule } from './components/menu-items/menu-items.module';
import { MenuItemExtrasModule } from './components/menu-item-extras/menu-item-extras.module';
import { HttpClientModule } from '@angular/common/http';
import { MenuCategoriesModule } from './components/menu-categories/menu-categories.module';
import { RestaurantSelectionModule } from './components/restaurant-selection/restaurant-selection.module';
import { OrderHistoryComponent } from './components/order-history/order-history.component';
import { OrderHistoryModule } from './components/order-history/order-history.module';
import { RestaurantManagementModule } from './components/restaurant-management/restaurant-management.module';
import { AuthGuard } from './guards/auth.guards';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { DiscountManagementModule } from './components/discount-management/discount-management.module';
import { RegionManagementModule } from './components/region-management/region-management.module';



@NgModule({
  declarations: [
    AppComponent,
    OrderHistoryComponent 
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    DashboardModule,    
    MenuItemsModule,
    MenuItemExtrasModule,
    InvoiceModule,
    SettingModule,
    ReportsModule,
    AuthModule,
    SharedModule,
    LocalizationModule,
    ProductsModule,
    SalesModule,
    VendorsModule,
    CouponsModule,
    PagesModule,
    MediaModule,
    MenusModule,
    UsersModule,
    AgGridModule,
    HttpClientModule,
    MenuCategoriesModule,
    RestaurantSelectionModule,
    OrderHistoryModule,
    RestaurantManagementModule,
    DiscountManagementModule,
    RegionManagementModule
  ],
  providers: [AuthGuard, {provide: LocationStrategy, useClass: HashLocationStrategy}],
  bootstrap: [AppComponent]
})
export class AppModule { }
