import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChange,
  ViewChild,
} from "@angular/core";
import { MenuItemExtraWithOptionsRequestModel } from "../../tables/menu-item-extra-request";
import { MenuItemExtraOptionRequest } from "../../tables/menu-item-extra-option-request";
import {
  ModalDismissReasons,
  NgbModal,
  NgbModalRef,
} from "@ng-bootstrap/ng-bootstrap";
import { MenuItemsService } from "../../service/menu.items.service";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { BehaviorSubject, Observable, Subject, observable } from "rxjs";
import { v4 as uuid } from "uuid";
import { CategoryRequestModel } from "../../tables/catergory-request";
import { CategoryService } from "../../service/category.service";
import { AccountService } from "../../service/account.service";
import {FormControl} from '@angular/forms';
import { DiscountService } from "../../service/discount.service";
import { DiscountRequestModel } from "../../tables/discountRequestModel";

@Component({
  selector: "app-discount-management-modal",
  templateUrl: "./discount-management-modal.component.html",
  styleUrls: ["./discount-management-modal.component.scss"],
})

export class DiscountManagementModalComponent {
  @ViewChild("menuItemExtraOptionModal") menuItemExtraOptionModal: NgbModalRef;
  @ViewChild("discountModal") menuItemExtraModal: NgbModalRef;

  @Input() addOrEdit: string;
  @Input() categoryId: Observable<string> = new Observable<string>();

  public closeResult: string;

  discount$: Observable<DiscountRequestModel> =
    new Observable<DiscountRequestModel>();

    public menuItems$: Observable<any>;

  menuItemExtra: any = {};

  restuarantId: string = "";
  dateNow = Date.now.toString();

  public menuItemsSelected = new FormControl();

  public typeOfDiscount = new FormControl();
  public discountTypes: string[] = ['%', 'R'];

  constructor(
    private modalService: NgbModal,
    public discountService: DiscountService,
    private accountService:AccountService
  ) {
    const user = this.accountService.getLoggedInUser();
      this.restuarantId = user.restaurantId;
this.menuItems$ = discountService.getMenuItemsByRestaurantIdNoPaging(user.restaurantId);
    this.discount$ = discountService.categoryEditInitialize;
  }
  ngOnInit(): void {
    // this.menuItemExtraId.subscribe(m => {if(m.length>0){
    //   this.menuItemExtra$ = this.menuItemService.getMenuItemExtraWithOptions(m);
    // }});
  }
  open() {
    this.modalService
      .open(this.menuItemExtraModal, { ariaLabelledBy: "modal-basic-title" })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }
  openMenuExtraOption(content) {
    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title" })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  changeCategoryObject(event: any) {
    this.discount$ = this.discountService.UpdateCatergoryObject(event);
  }

  createCategory() {
    this.discount$.subscribe((cat) => {
      cat.content.itemsToExclude = this.menuItemsSelected.value;
      cat.content.type = this.typeOfDiscount.value;
      if (cat.id === undefined) {
        cat.id = uuid();        
        cat.serviceProviderId = this.restuarantId;
        this.discountService.createDiscount(cat).subscribe((res) => {});
      } else {
        // if(!cat.promo.isTimePromo){
        //   cat.promo = undefined;
        // }
        this.discountService.editDiscount(cat).subscribe((res) => {});
      }
    });
  }

  ngAfterViewInit() {
    this.categoryId.subscribe((m) => {
      if (m.length > 0) {
        this.discount$ = this.discountService.getDiscountById(m);
        this.discount$.subscribe((d) => {
        this.menuItemsSelected.setValue(d.content.itemsToExclude);
        this.typeOfDiscount.setValue(d.content.type);
        }) 
      } else {
        this.discount$ = this.discountService.categoryEditInitialize;
      }
    });
  }
}
