<ng-template #menuItemExtraModal let-modal>
    <div class="modal-header">
        <h5 class="modal-title f-w-600" id="exampleModalLabel">{{this.addOrEdit}} Menu Item Extra</h5>
        <button type="button" class="close" aria-label="Close"
            (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <ng-container *ngIf="menuItemExtra$ | async as menuItemExtraRes">
    <div class="modal-body">
        <form class="needs-validation">
            <div class="form">
                <div class="form-group">
                    <label for="extraname" class="mb-1" >Menu Item Extra Name :</label>
                    <input class="form-control" id="validationCustom01" type="text" name="name" [(ngModel)]="menuItemExtraRes.name" (ngModelChange)="changeMenuItemExtra(menuItemExtraRes)">
                </div>
                <div class="form-group">
                    <label for="maxiumumordernumber" class="mb-1" >Maximum number of options :</label>
                    <input class="form-control" id="validationCustom01" type="number" name="optionsLimit" [(ngModel)]="menuItemExtraRes.optionsLimit" (ngModelChange)="changeMenuItemExtra(menuItemExtraRes)">
                </div>
                <div class="form-group">
                    <label for="extraRequired" class="mb-1">Extra is Required:</label>&nbsp;
                    <mat-slide-toggle
                      class="ml-2"
                      id="extraRequired"
                      name="isRequired"
                      [(ngModel)]="menuItemExtraRes.isRequired"
                      (change)="onChange($event)"
                      (ngModelChange)="changeMenuItemExtra(menuItemExtraRes)"
                    ></mat-slide-toggle>
                  </div>
                  
                <div class="form-header">
                    <h5>Menu Item Extras options</h5>
                </div>
                <button type="button" class="btn btn-primary" data-toggle="modal" data-original-title="test"
                data-target="#exampleModal" (click)="openMenuExtraOption(menuItemExtraOptionModal)" style="margin-bottom: 20px;">Add Menu Item Extra Option</button>

                <ng-container *ngIf="_extraOptions | async as extraOptionsRes">
                <div cdkDropList class="example-list" (cdkDropListDropped)="drop($event)">
                    <div class="example-box" *ngFor="let extraOption of extraOptionsRes " cdkDrag>
                        {{extraOption.name}}-{{extraOption.price}}
                        <a href="javascript:void(0)" (click)="editExtraOption(extraOption)"><i class='fa fa-edit f-12'></i></a>&nbsp;
                        <a href="javascript:void(0)" (click)="deleteExtraOptionToArray(extraOption)"><i class="fa fa-trash-o"></i></a>
                    </div>
                </div>
            </ng-container>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="modal.dismiss('Cross click')" (mouseup)="createMenuItemExtraWithOptions()">Save</button>
        <button class="btn btn-secondary" type="button" data-dismiss="modal"
            (click)="modal.dismiss('Cross click')">Close</button>
    </div>
</ng-container>
</ng-template>

<ng-template #menuItemExtraOptionModal let-modal>
    <div class="modal-header">
        <h5 class="modal-title f-w-600" id="exampleModalLabel">Add Menu Item Extra Option</h5>
        <button type="button" class="close" aria-label="Close"
            (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form class="needs-validation">
            <div class="form">
                <div class="form-group">
                    <label for="optionname" class="mb-1" >Extra Option Name :</label>
                    <input class="form-control" id="validationCustom01" type="text" name="name" [(ngModel)]="extraOption.name">
                </div>
                <div class="form-group">
                    <label for="descextra" class="mb-1" >Description :</label>
                    <input class="form-control" id="validationCustom01" type="text" name="description" [(ngModel)]="extraOption.description">
                </div>
                <div class="form-group">
                    <label for="priceoption" class="mb-1" >Price :</label>
                    <input class="form-control" id="validationCustom01" type="text" name="price" [(ngModel)]="extraOption.price">
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="addExtraOptionToArray(extraOption)">Save</button>
        <button class="btn btn-secondary" type="button" data-dismiss="modal"
            (click)="modal.dismiss('Cross click')">Close</button>
    </div>
</ng-template>