import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ProductsRoutingModule } from '../products/products-routing.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { GalleryModule } from '@ks89/angular-modal-gallery';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { SharedModule } from 'src/app/shared/shared.module';
import { MenuItemExtrasComponent } from './menu-item-extras.component';
import { MenuItemExtraListComponent } from './menu-item-extra-list/menu-item-extra-list.component';
import { MenuItemExtrasRoutingModule } from './menu-item-extras-routing.module';
import { RestaurantSelectionModule } from '../restaurant-selection/restaurant-selection.module';


@NgModule({
  declarations: [
    MenuItemExtrasComponent,
    MenuItemExtraListComponent
  ],
  imports: [
    Ng2SearchPipeModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ProductsRoutingModule,
    NgbModule,
    GalleryModule,
    CKEditorModule,
    NgxDropzoneModule,
    SharedModule,
    MenuItemExtrasRoutingModule,
    RestaurantSelectionModule       
  ]
})
export class MenuItemExtrasModule { }
