<ng-template #restaurantModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title f-w-600" id="exampleModalLabel">
      {{ this.addOrEdit }} Restaurant
    </h5>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click'); clearState()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <ng-container *ngIf="restaurant$ | async as restaurantRes">
    <div class="modal-body">
      <form class="needs-validation">
        <div class="form">
          <div class="form-group">
            <div class="box-input-file">
              <input
                class="upload"
                type="file"
                (change)="readUrl($event, restaurantRes)"
              />
              <ng-container *ngIf="restaurantRes.featuredImage">
                <img
                  class="imgUpload"
                  alt=""
                  [src]="getImage(restaurantRes.featuredImage)"
                  style="max-height: 120px"
                />
              </ng-container>
            </div>
          </div>
          <div class="form-group">
            <label for="validationCustom01" class="mb-1">Name :</label>
            <input
              class="form-control"
              id="validationCustom01"
              type="text"
              name="name"
              [(ngModel)]="restaurantRes.name"
              (ngModelChange)="changeRestaurantProps(restaurantRes)"
            />
          </div>
          <div class="form-group">
            <label for="validationCustom01" class="mb-1">Description :</label>
            <input
              class="form-control"
              id="validationCustom01"
              type="text"
              name="description"
              [(ngModel)]="restaurantRes.description"
              (ngModelChange)="changeRestaurantProps(restaurantRes)"
            />
          </div>
          <div class="form-group">
            <label for="validationCustom01" class="mb-1">Categories :</label>
            <input
              class="form-control"
              id="validationCustom01"
              type="number"
              name="categories"
              [(ngModel)]="restaurantRes.categories"
              (ngModelChange)="changeRestaurantProps(restaurantRes)"
            />
          </div>
          <mat-form-field class="example-full-width">
            <mat-label>Assignee</mat-label>
            <input type="text" matInput [formControl]="myControl" [matAutocomplete]="auto">
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                <mat-option *ngFor="let option of filteredOptions | async"  [value]="option">{{option.name}}</mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-primary"
        (click)="modal.dismiss('Cross click')"
        (mouseup)="createOrEditRestaurant()"
      >
        Save
      </button>
      <button
        class="btn btn-secondary"
        type="button"
        data-dismiss="modal"
        (click)="modal.dismiss('Cross click'); clearState()"
      >
        Close
      </button>
    </div>
  </ng-container>
</ng-template>
