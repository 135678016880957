import { EventEmitter, Injectable } from "@angular/core";

import { BehaviorSubject, Observable, of, Subject } from "rxjs";

import { DecimalPipe } from "@angular/common";
import { debounceTime, delay, map, switchMap, tap } from "rxjs/operators";
import { SortColumn, SortDirection } from "../directives/NgbdSortableHeader";
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { MenuItemExtraWithOptionsRequestModel } from "../tables/menu-item-extra-request";
import { MenuItemRequestModel } from "../tables/menu-item-request";
import { CategoryRequestModel } from "../tables/catergory-request";
import { RestaurantRequestModel } from "../tables/restaurantRequestModel";
import { PaymentDetails } from "../tables/paymentDetails";
import { ServiceProviderGroupRequest } from "../tables/serviceProviderRequestModel";

interface SearchResult {
  tableItem: any[];
  total: number;
}

interface State {
  page: number;
  pageSize: number;
  searchTerm: string;
  sortColumn: SortColumn;
  sortDirection: SortDirection;
}

const compare = (v1: string | number, v2: string | number) =>
  v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

function sort(tableItem: any[], column: SortColumn, direction: string): any[] {
  if (direction === "" || column === "") {
    return tableItem;
  } else {
    return [...tableItem].sort((a, b) => {
      const res = compare(a[column], b[column]);
      return direction === "asc" ? res : -res;
    });
  }
}

@Injectable({
  providedIn: "root",
})
export class RestaurantService {
  baseUrl = environment.apiUrl;

  private _loading$ = new BehaviorSubject<boolean>(true);
  public RestaurantIdUpdated = new EventEmitter<string>();
  private _restuarantId$ = new BehaviorSubject<string>("");

  private _restuarants$ = new BehaviorSubject<any[]>([]);
  private _restuarant$ = new BehaviorSubject<any>([]);  
  private _paymentDetails$ = new BehaviorSubject<any>([]);
  private _catergories$ = new BehaviorSubject<any[]>([]);
  private _catergory$ = new BehaviorSubject<any>({});
  private _catergoryToEdit$ = new BehaviorSubject<any>({});
  private _total$ = new BehaviorSubject<number>(0);
  private catergoryToEdit: any;
  private categories: any[];
  private category: any;

  userData;

  private _state: State = {
    page: 1,
    pageSize: 10,
    searchTerm: "",
    sortColumn: "",
    sortDirection: "",
  };

  constructor(private pipe: DecimalPipe, private http: HttpClient) {}

  get total$() {
    return this._total$.asObservable();
  }
  get loading$() {
    return this._loading$.asObservable();
  }
  get page() {
    return this._state.page;
  }
  get pageSize() {
    return this._state.pageSize;
  }
  get searchTerm() {
    return this._state.searchTerm;
  }
  get categoryEditInitialize() {
    return new Observable<any>((observer) => {
      observer.next({});
    });
  }
  get restaurantToEditInitialize() {return new Observable<any>(observer => {
    observer.next({})
  });}  

  UpdateCatergoryObject(val: any) {
    return new Observable<any>((observer) => {
      observer.next(val);
    });
  }

  UpdateMenuItem(val: any) {
    return new Observable<any>((observer) => {
      observer.next(val);
    });
  }

  UpdatePaymentDetails(val: any) {
    return new Observable<any>((observer) => {
      observer.next(val);
    });
  }

  setRestuarantId(restaurantId: string) {
    this.RestaurantIdUpdated.emit(restaurantId);
    return this._restuarantId$.next(restaurantId);
  }

  getRestuarantId() {
    return this._restuarantId$;
  }

  completeId() {
    return this._restuarantId$.complete();
  }

  public getAllRestuarants() {
    let params = new HttpParams();

    return this.http
      .get<any[]>(this.baseUrl + "RestaurantManagement/getallrestaurants")
      .pipe(
        tap((restuarants: any[]) => {
          if (restuarants) {
            return this._restuarants$.next(restuarants);
          }
        })
      );
  }
  public getRestaurantById(
    restaurantId: string,
  ) {

    let params = new HttpParams();
    params = params.append("restaurantId", restaurantId);

    return this.http
      .get<any[]>(
        this.baseUrl + "RestaurantManagement/getrestaurantbyId",
        { params }
      )
      .pipe(
        tap((restaurant: any) => {
          if (restaurant) {
            return this._restuarant$.next(restaurant);
          }
        })
      );
  }

  public addRestaurant(restauranToAdd: RestaurantRequestModel) {
    return this.http
      .post<any>(
        this.baseUrl + "RestaurantManagement/addrestaurant",
        restauranToAdd
      )
      .pipe(
        tap((added: any) => {
          if (added) {
            //log info here
          }
        })
      );
  }
  public editRestaurant(retaurantToEdit: RestaurantRequestModel) {
    return this.http
      .post<any>(
        this.baseUrl + "RestaurantManagement/editrestaurant",
        retaurantToEdit
      )
      .pipe(
        tap((added: any) => {
          if (added) {
            //log info here
          }
        })
      );
  }

  public setOnline(restaurantId: string) {
    return this.http
      .post<any>(
        this.baseUrl + "RestaurantManagement/setonline",
        {restaurantId:restaurantId}
      )
      .pipe(
        tap((isOnline: any) => {
          if (isOnline) {
            //log info here
          }
        })
      );
  }

  public getOnlineStatus(restaurantId: any) {
    let params = new HttpParams();
    params = params.append("restaurantId", restaurantId);

    return this.http
      .get<boolean>(
        this.baseUrl + "RestaurantManagement/getonlinestatus",
        { params }
      )
      .pipe(
        tap((isOnline: boolean) => {
          if (isOnline) {
            return isOnline;
          }
        })
      );
  }

  public getCategoriesForRestaurant(
    restaurantId: string,
    pageNumber: number,
    pageSize: number
  ) {
    let params = new HttpParams();
    params = params.append("restuarantId", restaurantId);

    if (pageNumber !== null && pageSize !== null) {
      params = params.append("pageNumber", pageNumber.toString());
      params = params.append("pageSize", pageSize.toString());
    }
    return this.http
      .get<any[]>(
        this.baseUrl + "RestaurantManagement/getrestaurantcategories",
        { params }
      )
      .pipe(
        tap((categories: any[]) => {
          if (categories) {
            return this._catergories$.next(categories);
          }
        })
      );
  }

  public getCategoriesForRestaurantNoPaging(restaurantId: string) {
    let params = new HttpParams();
    params = params.append("restuarantId", restaurantId);

    return this.http
      .get<any[]>(
        this.baseUrl + "RestaurantManagement/getrestaurantcategoriesnopaging",
        { params }
      )
      .pipe(
        tap((categories: any[]) => {
          if (categories) {
            return this._catergories$.next(categories);
          }
        })
      );
  }

  public getCategoryById(categoryId: string) {
    let params = new HttpParams();
    params = params.append("categoryId", categoryId);

    return this.http
      .get<any[]>(
        this.baseUrl + "RestaurantManagement/getrestaurantcategorybyid",
        { params }
      )
      .pipe(
        tap((category: any) => {
          if (category) {
            return this._catergory$.next(category);
          }
        })
      );
  }

  public createCategory(categoryToCreate: CategoryRequestModel) {
    return this.http
      .post<any>(
        this.baseUrl + "RestaurantManagement/addrestaurantcategory",
        categoryToCreate
      )
      .pipe(
        tap((added: any) => {
          if (added) {
            //log info here
          }
        })
      );
  }

  public editCategory(categoryToEdit: CategoryRequestModel) {
    return this.http
      .post<any>(
        this.baseUrl + "RestaurantManagement/editrestaurantcategory",
        categoryToEdit
      )
      .pipe(
        tap((menuItemExtraAdded: any[]) => {
          // if (menuItemExtraAdded) {
          //   this.tableItems.push(menuItemExtraAdded)
          //    return this._tableItem$.next(this.tableItems);
          // }
        })
      );
  }

  public updateCategoryIndexes(categories: Array<CategoryRequestModel>) {
    return this.http
      .post<any>(
        this.baseUrl + "RestaurantManagement/updatecategoryindexes",
        categories
      )
      .pipe(
        tap((menuItemExtraAdded: any[]) => {
          // if (menuItemExtraAdded) {
          //   this.tableItems.push(menuItemExtraAdded)
          //    return this._tableItem$.next(this.tableItems);
          // }
        })
      );
  }

  public deleteCategory(categoryToEdit: CategoryRequestModel) {
    return this.http
      .post<any>(
        this.baseUrl + "RestaurantManagement/deleterestaurantcategory",
        categoryToEdit
      )
      .pipe(
        tap((menuItemExtraAdded: any[]) => {
          // if (menuItemExtraAdded) {
          //   this.tableItems.push(menuItemExtraAdded)
          //    return this._tableItem$.next(this.tableItems);
          // }
        })
      );
  }
  public deleteRestaurant(restaurantId: string) {
    return this.http
      .post<any>(
        this.baseUrl + "RestaurantManagement/deleterestaurant",
        {restaurantId:restaurantId}
      )
      .pipe(
        tap((menuItemExtraAdded: any[]) => {
          // if (menuItemExtraAdded) {
          //   this.tableItems.push(menuItemExtraAdded)
          //    return this._tableItem$.next(this.tableItems);
          // }
        })
      );
  }

  public getPaymentDetails(
    restaurantId: string,
  ) {

    let params = new HttpParams();
    params = params.append("restaurantId", restaurantId);

    return this.http
      .get<any[]>(
        this.baseUrl + "RestaurantManagement/getpaymentdetails",
        { params }
      )
      .pipe(
        tap((paymentDetails: any) => {
          if (paymentDetails) {
            return this._paymentDetails$.next(paymentDetails);
          }
        })
      );
  }

  public addPaymentDetails(restauranToAdd: PaymentDetails) {
    return this.http
      .post<any>(
        this.baseUrl + "RestaurantManagement/addpaymentdetails",
        restauranToAdd
      )
      .pipe(
        tap((added: any) => {
          if (added) {
            //log info here
          }
        })
      );
  }
  public editPaymentDetails(retaurantToEdit: PaymentDetails) {
    return this.http
      .post<any>(
        this.baseUrl + "RestaurantManagement/editpaymentdetails",
        retaurantToEdit
      )
      .pipe(
        tap((added: any) => {
          if (added) {
            //log info here
          }
        })
      );
  }
  public deletePaymentDetails(restaurantId: string) {
    return this.http
      .post<any>(
        this.baseUrl + "RestaurantManagement/deletepaymentdetails",
        {restaurantId:restaurantId}
      )
      .pipe(
        tap((menuItemExtraAdded: any[]) => {
          // if (menuItemExtraAdded) {
          //   this.tableItems.push(menuItemExtraAdded)
          //    return this._tableItem$.next(this.tableItems);
          // }
        })
      );
  }

  public getAllServiceProviderGroups(){
    let params = new HttpParams();

    return this.http.get<any[]>(this.baseUrl + 'RestaurantManagement/getserviceprovidergroups').pipe(
      tap((restuarants: any[]) => {
        if (restuarants) {         
           return this._restuarants$.next(restuarants);
        }
      })
    );
  }

  public getServiceProvidergroup(
    restaurantId: string,
  ) {

    let params = new HttpParams();
    params = params.append("groupId", restaurantId);

    return this.http
      .get<any[]>(
        this.baseUrl + "RestaurantManagement/getserviceprovidergroup",
        { params }
      )
      .pipe(
        tap((restaurant: any) => {
          if (restaurant) {
            return this._restuarant$.next(restaurant);
          }
        })
      );
  }
  public addServiceProviderGroup(serviceProviderGroupToAdd: ServiceProviderGroupRequest) {
    return this.http
      .post<any>(
        this.baseUrl + "RestaurantManagement/addserviceprovidergroup",
        serviceProviderGroupToAdd
      )
      .pipe(
        tap((added: any) => {
          if (added) {
            //log info here
          }
        })
      );
  }
  public editServiceProviderGroup(serviceProviderGroupToEdit: ServiceProviderGroupRequest) {
    return this.http
      .post<any>(
        this.baseUrl + "RestaurantManagement/editserviceprovidergroup",
        serviceProviderGroupToEdit
      )
      .pipe(
        tap((added: any) => {
          if (added) {
            //log info here
          }
        })
      );
  }

  public deleteServiceProviderGroup(groupId: string) {
    return this.http
      .post<any>(
        this.baseUrl + "RestaurantManagement/deleteserviceprovidergroup",
        {id:groupId}
      )
      .pipe(
        tap((menuItemExtraAdded: any[]) => {
          // if (menuItemExtraAdded) {
          //   this.tableItems.push(menuItemExtraAdded)
          //    return this._tableItem$.next(this.tableItems);
          // }
        })
      );
  }
}
